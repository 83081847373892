import { useQueries } from '@tanstack/react-query';
import { fetchCurrentSessions, fetchOperations } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import countWorkdays from '../../utils/countWorkdays';
import { sortBy } from '../../utils/arrayUtils';
import { formatBrokenToolFrequency, formatDatetime } from '../../utils/fieldFormats';
import { countActiveToolChangeAlarms } from '../../utils/toolChangeAlarms';
import { Link } from 'react-router-dom';
import { Operation, Session } from '../../types/sharedTypeImpl';


interface BrokenToolsPerSessionProps {
    selectedSession: Session | null;
    onSessionSelected: (session: Session, sessionOps: Operation[]) => void;
}

export default function BrokenToolsPerSession({
    selectedSession,
    onSessionSelected,
}: BrokenToolsPerSessionProps) {

    const { data: sessions } = useFetchOnce({
        queryKey: ['/api/v1/equipments/current-sessions'],
        queryFn: async () => await fetchCurrentSessions(),
        select: sessions => sortBy([...sessions], it => it.equipmentDisplayName),
    });

    const sessionOperations = useQueries({
        queries: sessions?.map(session => ({
            queryKey: ['/api/v1/operations', session.equipment, session.product, session.start, session.end],
            queryFn: async () => await fetchOperations({ equipment: session.equipment, product: session.product, start: session.start, end: session.end }),
            // select: operations => sortBy(operations, 'time'),
            refetchOnWindowFocus: false,
        })) ?? [],
    });


    const handleRowClick = (session: Session, index: number) => {
        if (sessionOperations[index].isLoading || sessionOperations[index].isError) {
            return
        }
        onSessionSelected(session, sessionOperations[index].data);
    };

    return (
        <table className='table--mid-width'>
            <thead>
                <tr>
                    <th>Equipment</th>
                    <th>Product</th>
                    <th>Start</th>
                    <th>Unresolved count</th>
                    <th>Interval (days)</th>
                </tr>
            </thead>
            <tbody>
                {sessions?.map((session, idx) => {
                    const isSelected = selectedSession?.equipment === session.equipment;
                    const operations = sessionOperations[idx].data ?? null;
                    const alarmCount = operations
                        ? countActiveToolChangeAlarms(operations)
                        : null;
                    const frequency = alarmCount
                        ? countWorkdays(session.start, new Date()) / +alarmCount
                        : null;

                    return (
                        <tr
                            key={idx}
                            className={isSelected ? 'highlight--selected' : ''}
                            onClick={() => handleRowClick(session, idx)}
                        >
                            <td className='textAlignLeft'>
                                <Link to={`/machine-log#equipment=${session.equipment}`} className='link-button--non-intrusive'>
                                    {session.equipmentDisplayName}
                                </Link>
                            </td>
                            <td>{session.partDisplayName}</td>
                            <td>{formatDatetime(session.start)}</td>
                            <td>{alarmCount ?? '...'}</td>
                            <td>{formatBrokenToolFrequency(frequency)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
}
