import { mapBy } from '../../utils/arrayUtils';
import { Equipment } from '../../../../shared/types/mtlinkiTypes';
import { OeeRow } from '../../types/sharedTypeImpl';


export default function groupPerEquipment({
    equipments,
    shiftTable,
    hourTable,
    liveTable,
}: {
    equipments: Equipment[];
    shiftTable: OeeRow[];
    hourTable: OeeRow[];
    liveTable: OeeRow[];
}): Map<string, LiveDataPerEquipment> {
    const liveDataByEquipment = mapBy(liveTable, it => it.equipment);
    const hourDataByEquipment = mapBy(hourTable, it => it.equipment);
    const shiftDataByEquipment = mapBy(shiftTable, it => it.equipment);

    const dataPerEquipment = new Map();
    const individualAndAllEquipments = [
        ...equipments,
        { id: 'All equipments', displayName: 'All equipments' }
    ];
    for (const equipment of individualAndAllEquipments) {
        const displayName = equipment.displayName ?? equipment.id;
        const liveTable = liveDataByEquipment.get(equipment.id);
        const hourTable = hourDataByEquipment.get(equipment.id);
        const shiftTable = shiftDataByEquipment.get(equipment.id);
        dataPerEquipment.set(equipment.id, { displayName, liveTable, hourTable, shiftTable });
    }
    return dataPerEquipment;
}

export interface LiveDataPerEquipment {
    displayName: string;
    liveTable: OeeRow[];
    hourTable: OeeRow[];
    shiftTable: OeeRow[];
}
