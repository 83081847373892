import * as React from 'react';
import { useState } from 'react';
import CalibratedToolDialog from '../../components/calibratedToolDialog';
import CmmImportButton from '../../components/cmm-import/cmmImportButton';
import Input from '../../components/input';
import OperatorSelect from '../../components/operatorSelect';
import useFormKeyboardNavigation from '../../hooks/useFormKeyboardNavigation';
import { formatDatetime } from '../../utils/fieldFormats';
import { openLinkInNewTab } from '../../utils/navigation';
import RecordFormPositions from './recordFormPositions';
import getWayOutOfSpecValues from './wayOutOfSpec';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';


interface RecordFormProps {
    className?: string;
    protocol: ProtocolFinal;
    record: RecordDraft;
    setRecord: (record: RecordDraft) => void;
    activePositionIdx: number | null;
    setActivePositionIdx: (index: number) => void;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
    isSavedRecord: boolean;
    isViewMode: boolean;
    isSaving: boolean;
    draftSaveError: string | null;
    onDraftSave: () => void;
    onFormSubmit: () => void;
    onCloseClicked: () => void;
}

export default function RecordForm({
    className,
    protocol,
    record,
    setRecord,
    activePositionIdx,
    setActivePositionIdx,
    isDirty,
    setIsDirty,
    isSavedRecord,
    isViewMode,
    isSaving,
    draftSaveError,
    onDraftSave,
    onFormSubmit,
    onCloseClicked,
}: RecordFormProps) {
    const isProduction = import.meta.env.VITE_ENV === 'production';

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const formRef = useFormKeyboardNavigation();


    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value
        });
        setIsDirty(true);
    }
    const handleOrderNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value,
            ...(record.hasTargetQuantityChanged ? null : { targetQuantity: '' })
        });
        setIsDirty(true);
    }
    const handleTargetQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRecord({
            ...record,
            [event.target.name]: event.target.value,
            hasTargetQuantityChanged: true,
        });
        setIsDirty(true);
    }
    const handleOperatorChange = (operatorInitials: string) => {
        setRecord({
            ...record,
            'user': operatorInitials,
        });
        setIsDirty(true);
    }

    const handlePartCountEditEnable = () => {
        setRecord({
            ...record,
            isPartCountEditEnabled: true,
        });
        setIsDirty(true);
    }

    const handleCalibratedToolsSubmit = (selectedTools: string[]) => {
        setRecord({
            ...record,
            usedTools: selectedTools,
        });
        setIsDialogOpen(false);
        setIsDirty(true);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateAndAlert()) {
            onFormSubmit();
        }
    }

    const handleDraftSave = () => {
        onDraftSave();
    }

    const handleCloseClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        onCloseClicked();
    }

    function validateAndAlert(): boolean {
        // Check if all actual values are valid numbers; fail otherwise
        for (const [index, value] of record.positionValues.entries()) {
            const position = protocol.positions[index];
            if (position.type === 'measurement' && (value.value as string)?.length > 0) {
                const numberValue = +value.value;
                if (numberValue < 0 || isNaN(numberValue)) {
                    alert(`Actual value is invalid for position ${position.positionNumber}`);
                    return false;
                }
            }
        }
        if (record.targetQuantity && isNaN(+record.targetQuantity)) {
            alert('Target quantity is not a valid number');
            return false;
        }
        if (record.partCount && isNaN(+record.partCount)) {
            alert('Part count is not a valid number');
            return false;
        }

        // Check if all required fields are filled; fail otherwise
        if (!record.partCount) {
            alert(`Part count must be set`);
            return false;
        }

        // Check if all expected fields are filled; ask for confirmation if not
        if (!record.orderNumber) {
            const result = confirm(`Order number is not set. Continue anyway?`);
            if (!result) return false;
        }
        if (!record.targetQuantity) {
            const result = confirm(`Target quantity is not set. Continue anyway?`);
            if (!result) return false;
        }

        let emptyPosition = null;
        for (const [index, value] of record.positionValues.entries()) {
            const position = protocol.positions[index];

            if (position.toolType === 'CMM' || position.toolType === 'VMM') {
                continue;
            }
            if (position.type === 'measurement') {
                if (!value.value) {
                    emptyPosition = position.positionNumber;
                }
            } else if (position.type === 'visualInspection') {
                if (value.value == null) {
                    emptyPosition = position.positionNumber;
                }
            } else {
                throw new Error('Invalid position type');
            }
        }
        if (emptyPosition != null) {
            const result = confirm(`Actual value for position ${emptyPosition} is empty. Continue anyway?`);
            if (!result) return false;
        }

        if (record.generalVisualInspections == null) {
            const result = confirm(`General visual inspection has not been set. Continue anyway?`);
            if (!result) return false;
        }

        const wayOutOfSpecValues = getWayOutOfSpecValues(protocol, record.positionValues);
        for (const { position, positionValue } of wayOutOfSpecValues) {
            const result = confirm(`Position ${position.positionNumber} is way out of spec (value: ${positionValue.value}, nominal: ${position.nominal}). Continue anyway?`);
            if (!result) return false;
        }

        return true;
    }


    return (
        <div className={`${className} measurement-form`}>
            <button
                onClick={() => openLinkInNewTab('https://berryglade.sharepoint.com/:w:/s/BGNET/EQqUpUnfYEtHkUFOiuILpRMBhOc9JOqz4pizmpFhLq1B5w?e=ECJOby')}>
                Help
            </button>
            <br />


            <form
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <table className='record-form--table' style={{ marginBottom: '12px' }} >
                    <tbody>
                        <tr>
                            <td>Protocol name</td>
                            <td>
                                <input
                                    type='text'
                                    name='protocolName'
                                    value={protocol.protocolName}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Part name + rev</td>
                            <td>
                                <input
                                    type='text'
                                    name='partNameRev'
                                    value={protocol.partDisplayName}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>MT-LINKi program names</td>
                            <td>
                                <textarea
                                    name='mtLinkiProgramNames'
                                    value={protocol.mtLinkiProgramNames.join('\n')}
                                    rows={protocol.mtLinkiProgramNames.length}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Protocol revision</td>
                            <td>
                                <input
                                    type='text'
                                    name='protocolRevision'
                                    value={protocol.protocolRevision}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>General tolerance</td>
                            <td>
                                <input
                                    type='text'
                                    name='generalTolerance'
                                    value={protocol.generalTolerance}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>User</td>
                            <td>
                                <OperatorSelect
                                    operatorInitials={protocol.user ?? ''}
                                    isDisabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Comment</td>
                            <td>
                                <textarea
                                    rows={3}
                                    name='comment'
                                    value={protocol.comment}
                                    disabled={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Measurement time</td>
                            <td>
                                <input
                                    type='text'
                                    value={formatDatetime(record.measurementStart)}
                                    disabled={true}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Equipment</td>
                            <td>
                                <input
                                    type='text'
                                    value={record.equipmentDisplayName}
                                    disabled={true}
                                /><br />
                            </td>
                        </tr>
                        <tr>
                            <td>Order number</td>
                            <td>
                                <input
                                    type='text'
                                    name='orderNumber'
                                    value={record.orderNumber}
                                    onChange={handleOrderNumberChange}
                                    disabled={isViewMode}
                                /><br />
                            </td>
                        </tr>
                        <tr>
                            <td>Target quantity</td>
                            <td>
                                <Input
                                    type='number'
                                    name='targetQuantity'
                                    value={record.targetQuantity}
                                    onChange={handleTargetQuantityChange}
                                    disabled={isViewMode}
                                    step={1}
                                /><br />
                            </td>
                        </tr>
                        <tr>
                            <td>Part count</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Input
                                        style={{ flexGrow: 1 }}
                                        type='number'
                                        name='partCount'
                                        value={record.partCount}
                                        onChange={handleTextChange}
                                        disabled={!record.isPartCountEditEnabled}
                                        step={1}
                                    />
                                    {(!isViewMode && !record.isPartCountEditEnabled) &&
                                        <button
                                            type='button'
                                            onClick={handlePartCountEditEnable}>
                                            Edit
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                {(!isViewMode && !isProduction) &&
                    <CmmImportButton
                        protocol={protocol}
                        record={record}
                        setRecord={setRecord}
                        setIsDirty={setIsDirty}
                    />
                }

                {protocol.positions.length > 0 &&
                    <RecordFormPositions
                        protocol={protocol}
                        record={record}
                        setRecord={setRecord}
                        activePositionIdx={activePositionIdx}
                        setActivePositionIdx={setActivePositionIdx}
                        isViewMode={isViewMode}
                        setIsDirty={setIsDirty}
                    />
                }

                <table className='record-form--table' style={{ marginTop: '12px' }}>
                    <tbody>
                        <tr>
                            <td>Used tools</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <textarea
                                        style={{ flexGrow: 1 }}
                                        value={record.usedTools.join('\n')}
                                        rows={record.usedTools.length || 1}
                                        disabled={true} />
                                    {!isViewMode &&
                                        <button type='button' onClick={() => setIsDialogOpen(true)}>Update</button>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>User</td>
                            <td>
                                <OperatorSelect
                                    operatorInitials={record.user ?? ''}
                                    isDisabled={isViewMode}
                                    onChange={handleOperatorChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Comment</td>
                            <td>
                                <textarea
                                    rows={3}
                                    name='comment'
                                    value={record.comment}
                                    onChange={handleTextChange}
                                    disabled={isViewMode}
                                />
                            </td>
                        </tr>
                        {isViewMode &&
                            <tr>
                                <td>Measurement start</td>
                                <td>
                                    <input
                                        type='text'
                                        value={formatDatetime(record.measurementStart)}
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

                <div style={{ marginTop: '20px' }}>
                    {!isViewMode && <button type='submit'>Submit</button>}
                    {(!isViewMode && !isSavedRecord) && <button type='button' onClick={handleDraftSave}>Save draft</button>}
                    <button onClick={handleCloseClicked}>Close</button>
                    {
                        isSaving ? <span> Saving...</span>
                            : draftSaveError ? <span className='error'> Error: {draftSaveError}</span>
                                : (!isDirty && !isViewMode) && <span> Changes saved</span>
                    }
                </div>
            </form>

            <CalibratedToolDialog
                isOpen={isDialogOpen}
                initialSelection={record.usedTools}
                onCancel={() => { setIsDialogOpen(false); }}
                onSubmit={handleCalibratedToolsSubmit} />
        </div>
    );
}
