import { useEffect, useMemo, useRef } from 'react';


type UseAutoSaveProps = {
    isViewMode: boolean;
    isFinalized: boolean;
    isFinalSaving: boolean;
    isDirty: boolean;
    autoSaveCallback: () => void;
};

export default function useAutoSave({
    isViewMode,
    isFinalized,
    isFinalSaving,
    isDirty,
    autoSaveCallback,
}: UseAutoSaveProps) {
    const AUTO_SAVE_INTERVAL = 30_000;
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const latestCallbackRef = useRef(autoSaveCallback);


    useEffect(() => {
        latestCallbackRef.current = autoSaveCallback;
    }, [autoSaveCallback]);


    const scheduleAutoSave = useMemo(() => {
        return () => {
            timerRef.current = setInterval(() => {
                latestCallbackRef.current();
            }, AUTO_SAVE_INTERVAL);
            console.log('Auto save started');
        };
    }, []);

    const clearAutoSave = useMemo(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
                console.log('Auto save stopped');
            }
        };
    }, []);

    useEffect(() => {
        if (isViewMode || isFinalized || isFinalSaving || !isDirty) {
            clearAutoSave();
            return undefined;
        }

        scheduleAutoSave();
        return () => {
            clearAutoSave();
        };
    }, [isViewMode, isFinalized, isFinalSaving, isDirty, clearAutoSave, scheduleAutoSave]);
}
