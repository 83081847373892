import { useRef, useState } from 'react';
import { draftToProtocol } from '../../utils/conversion';
import { useProtocolForm } from './protocolFormContext';
import { useUpdateProtocol } from './useUpdateProtocol';
import { patchProtocol, upsertProtocol } from '../../api/requests';
import useAutoSave from '../../hooks/useAutoSave';
import { Protocol, ProtocolDraft } from '../../types/sharedTypeImpl';
import { ProtocolReview } from '../../../../shared/types/protocol';


interface UseProtocolSaveProps {
    isViewMode: boolean;
    isFinalSavedRef: React.MutableRefObject<boolean>;
    onFinalSaved: () => void;
    onReviewSaved: () => void;
}

export default function useProtocolSave({
    isViewMode,
    isFinalSavedRef,
    onFinalSaved,
    onReviewSaved,
}: UseProtocolSaveProps) {
    const [isAutoSaving, setIsAutoSaving] = useState(false);
    const [isFinalSaving, setIsFinalSaving] = useState(false);
    const isFinalSavePendingRef = useRef(false);

    const {
        protocol,
        pdfData,
        getPositionImages,
        isDirty,
        setIsDirty,
    } = useProtocolForm();

    const {
        setProtocolField,
    } = useUpdateProtocol();

    useAutoSave({
        isViewMode,
        isFinalized: isViewMode,
        isFinalSaving,
        isDirty,
        autoSaveCallback: async () => await handleDraftSave()
    });


    const handleDraftSave = async () => {
        if (isAutoSaving || isFinalSaving || isFinalSavedRef.current) return;
        setIsAutoSaving(true);
        console.log('Draft save started');
        await saveProtocol(protocol);

        setIsAutoSaving(false);
        console.log('Draft save ended');
        if (isFinalSavePendingRef.current) {
            isFinalSavePendingRef.current = false;
            handleFinalSave(protocol);
        }
    }

    const handleFinalSave = async (protocolDraft: ProtocolDraft) => {
        if (isFinalSaving || isFinalSavePendingRef.current || isFinalSavedRef.current) return;
        if (isAutoSaving) {
            isFinalSavePendingRef.current = true;
            return;
        }

        setIsFinalSaving(true);
        console.log('Final save started');

        const protocolFinal = draftToProtocol(protocolDraft);
        protocolFinal.status = 'review';
        const savedProtocol = await saveProtocol(protocolFinal);

        setIsFinalSaving(false);
        console.log('Final save ended');
        if (savedProtocol) {
            isFinalSavedRef.current = true;
            onFinalSaved();
        }
    }

    const saveProtocol = async (protocol: Protocol): Promise<Protocol | null> => {
        try {
            setIsAutoSaving(true);

            const pdf = (pdfData && protocol.pdfFileName == null) ? pdfData : null;
            const imageEntries = getPositionImages();
            const savedProtocol = await upsertProtocol(removeTempFields(protocol), pdf, imageEntries);

            setProtocolField('_id', savedProtocol._id);
            setProtocolField('pdfFileName', savedProtocol.pdfFileName);
            setIsDirty(false);
            return savedProtocol;
        } catch (error) {
            alert('Failed to save');
            return null;
        } finally {
            setIsAutoSaving(false);
        }
    }

    const handleReviewSave = async (review: ProtocolReview) => {
        try {
            setIsFinalSaving(true);

            const success = await patchProtocol(protocol._id, {
                reviews: [...(protocol.reviews ?? []), review],
                status: review.approved ? 'approved' : 'rejected',
            });
            if (success) {
                onReviewSaved();
            } else {
                alert('Failed to save');
            }
        } catch (error) {
            alert('Failed to save');
            return null;
        } finally {
            setIsFinalSaving(false);
        }
    }

    function removeTempFields(protocol: Protocol): Protocol {
        const copy = {
            ...protocol,
            positions: protocol.positions.map(p => ({ ...p })),
        };
        copy.positions.forEach(p => {
            delete p.imageFile;
        });
        return copy;
    }


    return {
        isSaving: isAutoSaving || isFinalSaving,
        handleDraftSave,
        handleFinalSave,
        handleReviewSave,
    }
}
