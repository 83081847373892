import { v4 as uuidv4 } from 'uuid';
import { maxOf, removeElementByIndex, sortBy } from '../../utils/arrayUtils';
import { getTolerances } from './generalTolerances';
import { useProtocolForm } from './protocolFormContext';
import { Position, ProtocolDraft } from '../../types/sharedTypeImpl';
import { Point } from '../../types';
import { PositionType } from '../../../../shared/types/protocol';
import partDisplayName from '../../../../shared/util/partDisplayName';


export const useUpdateProtocol = () => {

    const {
        protocol,
        setProtocol,
        activePositionIdx,
        setActivePositionIdx,
        setPdfData: setPdfDataInContext,
        setIsDirty,
    } = useProtocolForm();

    const setProtocolField = (field: keyof ProtocolDraft, value: string | string[]) => {
        console.log('setProtocolField', field, value);
        setProtocol((protocol: ProtocolDraft) => {
            const newProtocol = {
                ...protocol,
                [field]: value,
            };

            if (field === 'generalTolerance') {
                recalcAllTolerances(newProtocol, value as string);
            }

            if (field === 'partName' || field === 'partRevision') {
                newProtocol.partDisplayName = partDisplayName(newProtocol.partName, newProtocol.partRevision);
            }

            return newProtocol;
        });
        setIsDirty(true);
    };

    const setPositionField = (index: number, field: keyof Position, value: string) => {
        console.log('setPositionField', index, field, value);
        const positions = [...protocol.positions];
        positions[index] = {
            ...protocol.positions[index],
            [field]: value,
        };
        if (protocol.generalTolerance) {
            positions[index] = setTolerancesIf(positions[index], field, protocol.generalTolerance);
        }

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const setPositionLocation = (positionIdx: number, page: number, pdfCoords: Point, pdfCoords2?: Point) => {
        const positions = [...protocol.positions];
        positions[positionIdx].page = page;
        positions[positionIdx].x = pdfCoords.x;
        positions[positionIdx].y = pdfCoords.y;
        if (pdfCoords2) {
            positions[positionIdx].x2 = pdfCoords2.x;
            positions[positionIdx].y2 = pdfCoords2.y;
        } else {
            delete positions[positionIdx].x2;
            delete positions[positionIdx].y2;
        }

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const addPosition = (type: PositionType, page: number, pdfCoords: Point, pdfCoords2?: Point) => {
        const maxPosNum = maxOf(protocol.positions ?? [], (it: Position) => +it.positionNumber);
        const nextPosNum = maxPosNum ? Math.floor(maxPosNum) + 1 : 1;
        const newPosition: Position = {
            key: uuidv4(),
            type,
            page,
            x: pdfCoords.x,
            y: pdfCoords.y,
            ...(pdfCoords2 && { x2: pdfCoords2?.x }),
            ...(pdfCoords2 && { y2: pdfCoords2?.y }),
            positionNumber: nextPosNum.toString(),
        };

        const positions = [...protocol.positions ?? [], newPosition];
        setProtocol({
            ...protocol,
            positions,
        });
        setActivePositionIdx(positions.length - 1);
        setIsDirty(true);
    };

    const removePosition = (positionIdx: number) => {
        const positions = removeElementByIndex(protocol.positions, positionIdx);
        setProtocol({
            ...protocol,
            positions,
        });
        setActivePositionIdx(null);
        setIsDirty(true);
    };

    const setPositionImage = (index: number, imageData: File) => {
        const positions = [...protocol.positions];
        const position = positions[index];

        position.imageFile = imageData;

        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const setCurrentImage = (file: File) => {
        if (file && protocol.positions && activePositionIdx != null) {
            setPositionImage(activePositionIdx, file);
        }
    }

    const reorderPositions = () => {
        const positions = sortBy([...protocol.positions], (it => +it.positionNumber));
        setProtocol({
            ...protocol,
            positions,
        });
        setIsDirty(true);
    };

    const setPdfData = (pdfData: Uint8Array) => {
        setProtocolField('pdfFileName', null);
        setPdfDataInContext(pdfData);
        setIsDirty(true);
    }


    return {
        setActivePositionIdx,
        setProtocolField,
        setPositionField,
        setPositionLocation,
        addPosition,
        removePosition,
        setPositionImage,
        setCurrentImage,
        reorderPositions,
        setPdfData,
    }
};


function recalcAllTolerances(protocol: ProtocolDraft, generalTolerance: string) {
    protocol.positions = protocol.positions.map(it => recalcTolerances(it, generalTolerance));
}

const setTolerancesIf = (
    position: Position,
    changedField: keyof Position,
    generalTolerance: string
): Position => {
    const fieldsCauseRecalc: Array<keyof Position> = ['nominal', 'useGeneralTolerance', 'isChamfer'];
    if (position.useGeneralTolerance && fieldsCauseRecalc.includes(changedField)) {
        const tolerances = getTolerances(position.nominal, generalTolerance, position.isChamfer);
        return {
            ...position,
            upperTolerance: tolerances?.upperTolerance ?? '',
            lowerTolerance: tolerances?.lowerTolerance ?? '',
        }
    } else if (!position.useGeneralTolerance && changedField === 'useGeneralTolerance') {
        return {
            ...position,
            upperTolerance: '',
            lowerTolerance: '',
        }
    }
    return position;
};

function recalcTolerances(
    position: Position,
    generalTolerance: string
): Position {
    if (position.useGeneralTolerance) {
        const tolerances = getTolerances(position.nominal, generalTolerance, position.isChamfer);
        return {
            ...position,
            upperTolerance: tolerances.upperTolerance,
            lowerTolerance: tolerances.lowerTolerance,
        };
    }
    return position;
}
