import { useRef } from 'react';
import ProtocolPdf from '../../components/protocolPdf/protocolPdf';
import VisualInspectionImage from '../../components/visualInspectionImage';
import useConfirmOnLeave from '../../hooks/useConfirmOnLeave';
import { Point } from '../../types';
import ProtocolForm from './protocolForm';
import { useProtocolForm } from './protocolFormContext';
import useProtocolSave from './useProtocolSave';
import useProtocolInit from './useProtocolnit';
import { useUpdateProtocol } from './useUpdateProtocol';
import { useNavigate } from 'react-router-dom';
import { PositionType } from '../../../../shared/types/protocol';


export default function ProtocolView() {

    const isFinalSavedRef = useRef(false);

    const {
        protocol,
        setProtocol,
        pdfData,
        setPdfData,
        activePositionIdx,
        showAllPositions,
        isDirty,
    } = useProtocolForm();

    const {
        addPosition,
        setPositionLocation,
        setCurrentImage,
    } = useUpdateProtocol();

    const {
        isProtocolLoading,
        isPdfLoading,
        protocolError,
        mode,
        isViewMode,
        savedPositionImages,
    } = useProtocolInit(setProtocol, setPdfData);

    const {
        isSaving,
        handleDraftSave,
        handleFinalSave,
        handleReviewSave,
    } = useProtocolSave({
        isViewMode,
        isFinalSavedRef,
        onFinalSaved: () => handleClose(),
        onReviewSaved: () => handleClose(),
    });

    useConfirmOnLeave(() => isDirty && !isFinalSavedRef.current, !isViewMode);

    const navigate = useNavigate();


    function handleAddPosition(type: PositionType, page: number, pdfCoords: Point, pdfCoords2?: Point) {
        addPosition(type, page, pdfCoords, pdfCoords2);
    }

    function handleUpdatePosition(page: number, pdfCoords: Point, pdfCoords2?: Point) {
        setPositionLocation(activePositionIdx, page, pdfCoords, pdfCoords2);
    }

    const handleClose = () => {
        navigate('/protocol-list');
    }


    const activePosition = activePositionIdx != null ? protocol?.positions[activePositionIdx] : null;
    const shouldShowImagePane = activePosition?.type === 'visualInspection';
    const positionImage = shouldShowImagePane && (activePosition?.imageFile ?? savedPositionImages?.[activePositionIdx]);

    if (isProtocolLoading) return <div>Loading...</div>;
    if (protocolError) return <div>An error has occurred: {protocolError.message}</div>;
    return (
        <div className='measurement-container'>
            <ProtocolPdf
                className='measurement-pdf-container'
                isViewMode={isViewMode}
                positions={protocol?.positions}
                activePositionIdx={activePositionIdx}
                showAllPositions={showAllPositions}
                pdfContent={pdfData}
                isPdfLoading={isPdfLoading}
                onAddPosition={handleAddPosition}
                onUpdatePosition={handleUpdatePosition}
            />

            <div className='measurement-form-pane'>
                {protocol && <ProtocolForm
                    className='measurement-form-top'
                    mode={mode}
                    isSaving={isSaving}
                    onFormSubmit={handleFinalSave}
                    onReviewSubmit={handleReviewSave}
                    onDraftSave={handleDraftSave}
                    onCloseClicked={handleClose}
                />}

                {shouldShowImagePane && <VisualInspectionImage
                    className='measurement-form-bottom'
                    imageData={positionImage}
                    isViewMode={isViewMode}
                    onImageChange={setCurrentImage}
                />}
            </div>
        </div>
    );
}
