import { ReadMeasurementsResult } from './types';


export default async function readKeyenceCmmFile(cmmFileText: string): Promise<ReadMeasurementsResult> {
    try {
        const table = cmmFileText.trim().split('\n').map(row => row.split(';').map(cell => cell.trim()));

        const isValidated = table.every(row => row.length === 6);
        if (!isValidated) {
            return { success: false, errorMessage: 'Unrecognized Mitutoyo format' };
        }

        const measurements = [];
        for (const row of table) {
            const position = row[0].match(/Pos ?(\d+(\.\d+)?).*?/)?.[1];
            const actualAsFloat = Number.parseFloat(row[1].replace(',', '.'));
            if (!position || isNaN(actualAsFloat)) continue;
            measurements.push({ position, value: actualAsFloat.toFixed(3) });
        }

        return {
            success: true,
            source: 'cmm',
            sourceSubType: 'keyence',
            measurements,
            errorMessage: null,
        }
    } catch (error) {
        console.error('Error reading file:', error);
        return { success: false, errorMessage: 'Error reading file' };
    }
}
