import { useEffect, useRef, useState } from 'react';
import PdfContextMenu from '../../pages/protocol-view/pdfContextMenu';
import { Point, Size } from '../../types';
import ArrowMarker, { ArrowMarkerTrigger } from './arrowMarker';
import ButtonBar from './buttonBar';
import CircleMarker, { CircleMarkerTriggers } from './circleMarker';
import useArrowCreate, { ArrowCoords } from './useArrowCreate';
import useRenderPdf from './useRenderPdf';
import useZoomPan from './useZoomPan';
import { defaultZoomPanValues, pdfToContainerCoords, pdfToScreenCoords, screenToPdfCoords } from './zoomPanUtils';
import { Position } from '../../types/sharedTypeImpl';
import { PositionType } from '../../../../shared/types/protocol';


interface ProtocolPdfProps {
    className?: string;
    isViewMode: boolean;
    positions?: Position[];
    activePositionIdx: number | null;
    showAllPositions?: boolean;
    pdfContent: Uint8Array | null;
    isPdfLoading?: boolean;
    onAddPosition?: (type: PositionType, page: number, pdfCoords: Point, pdfCoords2?: Point) => void;
    onUpdatePosition?: (page: number, pdfCoords: Point, pdfCoords2?: Point) => void;
}

export default function ProtocolPdf({
    className,
    isViewMode,
    positions,
    activePositionIdx,
    showAllPositions,
    pdfContent,
    isPdfLoading,
    onAddPosition,
    onUpdatePosition,
}: ProtocolPdfProps) {
    //Pdf and zoom/pan state
    const [pageCount, setPageCount] = useState<number | null>(null);
    const [pdfSize, setPdfSize] = useState<Size | null>(null);
    const [page, setPage] = useState<number>(1);
    const [scale, setScale] = useState<number | null>(null);
    const offset = useRef<Point>({ x: 0, y: 0 });

    //Div and marker refs
    const [isDrawingArrow, setIsDrawingArrow] = useState(false);
    const mouse2DragRef = useRef<ArrowCoords | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const pdfCanvasRef = useRef<HTMLCanvasElement>(null);
    const tempArrowMarkerRef = useRef<ArrowMarkerTrigger | null>(null);
    const markerRefs = useRef(new Map<number, CircleMarkerTriggers | ArrowMarkerTrigger | undefined>());

    const [menuCoords, setMenuCoords] = useState<Point | null>(null);

    const activePosition = activePositionIdx != null ? positions?.[activePositionIdx] : null;
    const visiblePositions = showAllPositions ? positions
        : activePosition ? [activePosition]
            : [];
    const activeVisiblePositionIdx = activePositionIdx != null && (showAllPositions ? activePositionIdx : 0);


    useEffect(() => {
        if (activePosition?.page != null) {
            setPage(activePosition.page);
        }
    }, [activePosition]);

    const handleOnPageSizeLoaded = (size: Size) => {
        // pdfSize.current = size;
        setPdfSize(size);

        const containerSize: Size = { width: containerRef.current.clientWidth, height: containerRef.current.clientHeight };
        const [newOffset, newScale] = defaultZoomPanValues(containerSize, size);
        offset.current = newOffset;
        setScale(newScale);
    };

    useRenderPdf({
        canvasRef: pdfCanvasRef,
        pdfContent,
        page,
        scale,
        onPageCountLoaded: setPageCount,
        onPageSizeLoaded: handleOnPageSizeLoaded,
        onRendered: onZoomPan,
    });

    const { fitToHeight, onMouseWheel, onMouseDown, onMouseMove } = useZoomPan({
        containerRef,
        contentRef: pdfCanvasRef,
        pdfSize,
        scale,
        setScale,
        offset,
        onZoomPan,
    });

    useArrowCreate({
        isEnabled: !isViewMode,
        pdfSize,
        arrowCoordsRef: mouse2DragRef,
        screenToPdfCoords: (screenCoords) => screenToPdfCoords(screenCoords, offset.current, scale),
        onMinDistanceMoved: () => setIsDrawingArrow(true),
        onMove: () => tempArrowMarkerRef.current?.updatePosition(),
        onEnd: (start, end) => {
            console.log('Arrow created', start, end);
            setMenuCoords(pdfToScreenCoords(end, offset.current, scale));
        },
        onCancel: () => setIsDrawingArrow(false),
    });

    async function onZoomPan() {
        pdfCanvasRef.current.style.transform = `translate(${offset.current.x}px, ${offset.current.y}px)`;
        markerRefs.current.forEach((ref) => {
            ref?.updatePosition();
        });
        tempArrowMarkerRef.current?.updatePosition();
    }

    function handleMenuClose() {
        setMenuCoords(null);
        setIsDrawingArrow(false);
        mouse2DragRef.current = null;
    }


    const showMenu = menuCoords && mouse2DragRef.current != null;

    if (pdfContent == null) {
        return <div className={className}>{isPdfLoading ? 'Loading pdf...' : 'No pdf set'}</div>
    }
    return (
        <>
            <div
                ref={containerRef}
                className={className}
                style={{ overflow: 'hidden', position: 'relative' }}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onWheel={onMouseWheel}
                onContextMenu={(e) => e.preventDefault()}
            >
                <canvas
                    ref={pdfCanvasRef}
                    style={{ display: 'inline-block' }}
                />

                {visiblePositions?.map((position, index) =>
                    <PositionMarkers
                        key={index}
                        position={position}
                        index={index}
                        page={page}
                        showDescription={index === activeVisiblePositionIdx}
                        pdfToScreenCoords={(pdfCoords) => pdfToContainerCoords(pdfCoords, offset.current, scale)}
                        setRef={(index, ref) => {
                            markerRefs.current.set(index, ref);
                        }}
                    />
                )}
                {isDrawingArrow &&
                    <ArrowMarker
                        ref={tempArrowMarkerRef}
                        coordsRef={mouse2DragRef}
                        pdfToScreenCoords={(pdfCoords) => pdfToContainerCoords(pdfCoords, offset.current, scale)}
                    />
                }


                <ButtonBar
                    pageCount={pageCount}
                    page={page}
                    setPage={setPage}
                    onFitToHeight={fitToHeight}
                />
            </div>

            {showMenu && <PdfContextMenu
                page={page}
                screenCoords={menuCoords}
                pdfCoords={mouse2DragRef.current?.start}
                pdfCoords2={isDrawingArrow ? mouse2DragRef.current?.end : undefined}
                activePosition={activePosition}
                onAddPosition={onAddPosition}
                onUpdatePosition={onUpdatePosition}
                onClose={handleMenuClose}
            />}
        </>
    );
}

function PositionMarkers({
    position,
    index,
    page,
    showDescription,
    pdfToScreenCoords,
    setRef,
}: {
    position: Position,
    index: number,
    page: number,
    showDescription: boolean,
    pdfToScreenCoords: (pdfCoords: Point) => Point,
    setRef: (index: number, ref: CircleMarkerTriggers | ArrowMarkerTrigger) => void,
}) {

    const isCorrectPage = position.page === page;
    const showCircle = isCorrectPage && position.x2 == null;
    const showArrow = isCorrectPage && position.x2 != null && position.y2 != null;

    return (
        <>
            {showCircle &&
                <CircleMarker
                    ref={(ref) => setRef(index, ref)}
                    pdfPosition={{ x: position.x, y: position.y }}
                    pdfToScreenCoords={pdfToScreenCoords}
                    description={showDescription ? position.onPdfDescription : undefined}
                />
            }
            {showArrow &&
                <ArrowMarker
                    ref={(ref) => setRef(index, ref)}
                    pdfStart={{ x: position.x, y: position.y }}
                    pdfEnd={{ x: position.x2!, y: position.y2! }}
                    pdfToScreenCoords={pdfToScreenCoords}
                    description={showDescription ? position.onPdfDescription : undefined}
                />
            }
        </>
    );
}
