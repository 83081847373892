import countWorkdays from '../../utils/countWorkdays';
import { sortByDesc } from '../../utils/arrayUtils';
import { formatBrokenToolFrequency } from '../../utils/fieldFormats';
import { countUnfixedUnscheduledToolChangesByTool } from '../../utils/toolChangeAlarms';
import { Operation, Session } from '../../types/sharedTypeImpl';
import { Tool } from '../../components/operation-dialog/operationDraft';


interface BrokenToolsPerToolProps {
    session: Session;
    sessionOperations: Operation[];
    selectedTool: Tool | null;
    onToolSelected: (tool: Tool, toolOps: Operation[]) => void;
}

export default function BrokenToolsPerTool({
    session,
    sessionOperations,
    selectedTool,
    onToolSelected,
}: BrokenToolsPerToolProps) {

    const sessionLengthDays = countWorkdays(session.start, new Date());
    const alarmCounts = sortByDesc(
        countUnfixedUnscheduledToolChangesByTool(sessionOperations),
        it => it.count
    );


    const handleRowClick = (toolVariant: string, toolNumber: string, toolOps: Operation[]) => {
        onToolSelected({ toolVariant, toolNumber }, toolOps);
    };

    const isSameTool = (a: Tool, b: Tool | null): boolean => {
        return a.toolVariant === b?.toolVariant && a.toolNumber === b?.toolNumber;
    }

    return (
        <table className='table--mid-width' style={{ marginTop: '16px' }} >
            <thead>
                <tr>
                    <th>Tool</th>
                    <th>Broken tools count</th>
                    <th>Interval (days)</th>
                </tr>
            </thead>
            <tbody>
                {alarmCounts.map(it => (
                    <tr
                        key={it.toolNumber}
                        className={isSameTool(it, selectedTool) ? 'highlight--selected' : ''}
                        onClick={() => handleRowClick(it.toolVariant, it.toolNumber, it.relatedOperations)}
                    >
                        <td>{`${it.toolVariant} ${it.toolNumber}`}</td>
                        <td>{it.count}</td>
                        <td>{formatBrokenToolFrequency(it.count > 0 ? sessionLengthDays / it.count : null)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
