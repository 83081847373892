import { useRef, useState } from 'react';
import importIssuesAnalysis from './importIssuesAnalysis';
import ImportSelectionDialog from './importSelectionDialog';
import readCmmFile from './readCmmFile';
import { ImportIssuesResult, Measurement, ReadMeasurementsResult } from './types';
import { ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';


interface CmmImportButtonProps {
    style?: React.CSSProperties;
    protocol: ProtocolFinal;
    record: RecordDraft;
    setRecord: (record: RecordDraft) => void;
    setIsDirty: (isDirty: boolean) => void;
}

export default function CmmImportButton({
    style,
    protocol,
    record,
    setRecord,
    setIsDirty,
}: CmmImportButtonProps) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [importIssues, setImportIssues] = useState<ImportIssuesResult[] | null>(null);
    const fileInputRef = useRef(null);
    const importResultRef = useRef<ReadMeasurementsResult | null>(null);


    function handleButtonClick() {
        fileInputRef.current.click();
    }

    async function handleFileSelected(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files[0];
        event.target.value = null;

        const importResult = await readCmmFile(file);
        if (!importResult.success) {
            alert(importResult.errorMessage);
            return;
        }
        importResultRef.current = importResult;

        const importIssues = importIssuesAnalysis(protocol, record, importResult.measurements);
        setImportIssues(importIssues);
        setIsDialogOpen(true);
    }

    function onDialogSubmit(valuesToImport: Measurement[]) {
        applyImportedValues(valuesToImport);
        setImportIssues(null);
        setIsDialogOpen(false);
        importResultRef.current = null;
    }

    function onDialogCancel() {
        setImportIssues(null);
        setIsDialogOpen(false);
        importResultRef.current = null;
    }

    function applyImportedValues(measurements: Measurement[]) {
        const newPositionValues = [...record.positionValues];
        for (const measurement of measurements) {
            const positionIdx = protocol.positions.findIndex(it => it.positionNumber === measurement.position);
            newPositionValues[positionIdx] = {
                value: measurement.value,
                source: importResultRef.current.source,
                sourceSubType: importResultRef.current.sourceSubType,
            }
        }

        setRecord({ ...record, positionValues: newPositionValues });
        setIsDirty(true);
    }


    return (
        <div style={style}>
            <button type='button' onClick={handleButtonClick}>Import CMM/VMM</button>
            <input
                type='file'
                accept='.xlsx, .xlsm, .xls, .csv'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileSelected}
                tabIndex={-1}
            />
            {importIssues && (
                <ImportSelectionDialog
                    isOpen={isDialogOpen}
                    importIssues={importIssues}
                    onCancel={onDialogCancel}
                    onSubmit={onDialogSubmit}
                />
            )}
        </div>
    );
}
