import { useState } from 'react';
import { fetchProductComment } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import ProductCommentDialog from './productCommentDialog';


interface ProductCommentProps {
    selectedPartName: string;
}

export default function ProductComment({
    selectedPartName,
}: ProductCommentProps) {

    const [isProductCommentDialogOpen, setIsProductCommentDialogOpen] = useState(false);


    const { data: productComment, refetch } = useFetchOnce({
        queryKey: ['productComment', selectedPartName],
        queryFn: async () => fetchProductComment(selectedPartName),
        enabled: !!selectedPartName,
    });


    return (
        <>
            <div id='productCommentContainer'>
                <textarea id='productCommentText' value={productComment ?? ''} readOnly />
                <button disabled={selectedPartName == null} onClick={() => setIsProductCommentDialogOpen(true)}>Edit</button>
            </div>

            {isProductCommentDialogOpen && <ProductCommentDialog
                isOpen={isProductCommentDialogOpen}
                product={selectedPartName}
                initialProductComment={productComment}
                onSubmited={() => refetch()}
                onClose={() => setIsProductCommentDialogOpen(false)}
            />}
        </>
    );
}
