import { spcChecks } from '../../pages/record-view/spcChecks';
import { ProtocolFinal, RecordDraft, RecordWithProtocol } from '../../types/sharedTypeImpl';


export const MAX_DATA_POINTS = 15;

export interface DataPoint {
    value: number | null,
    valueAsString: string | null,
    partCount: number,
    measurementStart: Date,
    shouldDraw: boolean,
    failedSpcCheck?: boolean,
}

export default function transformDataPoints(
    protocol: ProtocolFinal,
    prevRecords: RecordWithProtocol[],
    currentRecord: RecordDraft,
    selectedPositionIdx: number,
): DataPoint[] {
    const position = protocol.positions[selectedPositionIdx];

    const values = [
        ...getPrevPoints(prevRecords, position.positionNumber),
        getCurrentPoint(currentRecord, selectedPositionIdx)
    ];
    setSpcFlags(values, protocol, selectedPositionIdx);
    return getRecentEntries(values);
}

function getPrevPoints(prevRecords: RecordWithProtocol[], positionNumber: string): DataPoint[] {
    const dataPoints: DataPoint[] = [];
    for (const record of prevRecords) {
        if (record.isDraft) continue;

        const positionIndex = record.protocol.positions.findIndex(it => it.positionNumber === positionNumber);
        if (positionIndex === -1) continue;
        if (record.protocol.positions[positionIndex].type === 'visualInspection') continue;
        if (record.positionValues[positionIndex]?.value == null) continue;

        const valueAsString = record.positionValues[positionIndex].value as string;
        const valueAsNumber = +valueAsString;
        dataPoints.push({
            value: valueAsNumber,
            valueAsString,
            partCount: +record.partCount,
            measurementStart: record.measurementStart,
            shouldDraw: true,
        });
    }
    return dataPoints;
}

function getCurrentPoint(currentRecord: RecordDraft, selectedPositionIdx: number): DataPoint {
    const valueAsString = currentRecord.positionValues[selectedPositionIdx].value as string;
    const valueAsNumber = valueAsString?.length > 0 ? Number.parseFloat(valueAsString) : null;
    const shouldDraw = valueAsNumber != null && !isNaN(valueAsNumber);
    return {
        value: valueAsNumber,
        valueAsString,
        partCount: +currentRecord.partCount,
        measurementStart: currentRecord.measurementStart,
        shouldDraw,
    }
}

function setSpcFlags(dataEntries: DataPoint[], protocol: ProtocolFinal, selectedPositionIdx: number) {
    const values = dataEntries.map(it => it.value);
    const position = protocol.positions[selectedPositionIdx];
    const flaggedValues = spcChecks(values, +position.nominal, +position.upperTolerance, +position.lowerTolerance);
    for (let i = 0; i < flaggedValues.length; i++) {
        dataEntries[i].failedSpcCheck = flaggedValues[i].failedSpcCheck;
    }
}

function getRecentEntries(dataEntries: DataPoint[]): DataPoint[] {
    return dataEntries.slice(-MAX_DATA_POINTS);
}
