import { useState } from 'react';
import { openLinkInNewTab } from '../../utils/navigation';
import OperatorSelect from '../../components/operatorSelect';
import useFormKeyboardNavigation from '../../hooks/useFormKeyboardNavigation';
import { readFileAsUint8Array } from '../../utils/readFile';
import { toleranceStandards } from './generalTolerances';
import ProgramNamesList from './programNamesList';
import { useProtocolForm } from './protocolFormContext';
import ProtocolFormPositions from './protocolFormPositions';
import { useUpdateProtocol } from './useUpdateProtocol';
import { validateProtocolDraft, validateProtocolReview } from './validateProtocolForm';
import { formatDatetime } from '../../utils/fieldFormats';
import { ProtocolDraft } from '../../types/sharedTypeImpl';
import { ProtocolReview } from '../../../../shared/types/protocol';


interface ProtocolFormProps {
    className?: string;
    mode: 'edit' | 'view' | 'review';
    isSaving: boolean;
    onFormSubmit: (protocol: ProtocolDraft) => void;
    onReviewSubmit: (review: ProtocolReview) => void;
    onDraftSave: () => void;
    onCloseClicked: () => void;
}

export default function ProtocolForm({
    className,
    mode,
    isSaving,
    onFormSubmit,
    onReviewSubmit,
    onDraftSave,
    onCloseClicked,
}: ProtocolFormProps) {

    const isFormReadOnly = mode === 'view' || mode === 'review';
    const [reviewer, setReviewer] = useState('');
    const [reviewComment, setReviewComment] = useState('');

    const {
        protocol,
        pdfData,
        isDirty,
    } = useProtocolForm();

    const {
        setProtocolField,
        setPdfData,
    } = useUpdateProtocol();

    const formRef = useFormKeyboardNavigation();

    const handlePdfFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setPdfData(await readFileAsUint8Array(file));
        setProtocolField('pdfFileName', null);//Reset file name, since the field refers to the path in storage
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setProtocolField(event.target.name as keyof ProtocolDraft, event.target.value);
    }

    const handleProgramNamesChange = (programNames: string[]) => {
        setProtocolField('mtLinkiProgramNames', programNames);
    }

    const handleTolerancSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (!event.target.value) {
            return;
        }

        setProtocolField('generalTolerance', event.target.value);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (mode !== 'edit') return;

        if (!pdfData) {
            alert('Must set a pdf');
            return;
        }
        if (!validateProtocolDraft(protocol)) {
            return;
        }

        onFormSubmit(protocol);
    }

    const handleSaveDraft = (e: React.MouseEvent) => {
        e.preventDefault();
        onDraftSave();
    }

    const handleApproveClicked = (e: React.MouseEvent) => {
        e.preventDefault();

        const review = {
            approved: true,
            operator: reviewer,
        };

        if (!validateProtocolReview(review, protocol)) {
            return;
        }
        if (confirm('Approve?')) {
            onReviewSubmit({ approved: true, operator: reviewer });
        }
    }

    const handleRejectClicked = (e: React.MouseEvent) => {
        e.preventDefault();

        const review = {
            approved: false,
            operator: reviewer,
            comment: reviewComment,
        };

        if (!validateProtocolReview(review, protocol)) {
            return;
        }
        if (confirm('Reject?')) {
            onReviewSubmit({ approved: false, operator: reviewer, comment: reviewComment });
        }
    }

    const handleCloseClicked = (e: React.MouseEvent) => {
        e.preventDefault();
        onCloseClicked();
    }


    const latestReview = protocol.reviews?.at(-1);
    const showReviewer = mode === 'review' || latestReview;
    const showReviewComment = mode === 'review' || protocol.status === 'rejected';

    return (
        <div className={`${className} measurement-form`}>
            <button
                onClick={() => openLinkInNewTab('https://berryglade.sharepoint.com/:w:/s/BGNET/EQqUpUnfYEtHkUFOiuILpRMBhOc9JOqz4pizmpFhLq1B5w?e=ECJOby')}>
                Help
            </button>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
            >
                <table className='record-form--table'>
                    <tbody>
                        <tr>
                            <td>Pdf file</td>
                            <td>
                                <input
                                    type='file'
                                    accept='.pdf'
                                    onClick={(e) => isFormReadOnly ? e.preventDefault() : null}
                                    onChange={handlePdfFileChange}
                                    readOnly={isFormReadOnly}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Protocol name</td>
                            <td>
                                <input
                                    type='text'
                                    name='protocolName'
                                    value={protocol.protocolName ?? ''}
                                    onChange={handleTextChange}
                                    readOnly={isFormReadOnly}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Part name</td>
                            <td>
                                <input
                                    type='text'
                                    name='partName'
                                    value={protocol.partName ?? ''}
                                    onChange={handleTextChange}
                                    readOnly={isFormReadOnly}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Part revision</td>
                            <td>
                                <input
                                    type='text'
                                    name='partRevision'
                                    value={protocol.partRevision ?? ''}
                                    onChange={handleTextChange}
                                    readOnly={isFormReadOnly}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>MT-LINKi program names</td>
                            <td>
                                <ProgramNamesList
                                    programNames={protocol.mtLinkiProgramNames ?? []}
                                    setProgramNames={handleProgramNamesChange}
                                    readOnly={isFormReadOnly}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>General tolerance</td>
                            <td>
                                <select
                                    style={{ pointerEvents: isFormReadOnly ? 'none' : 'auto' }}
                                    value={protocol.generalTolerance ?? ''}
                                    onChange={handleTolerancSelect}
                                >
                                    <option value='' />
                                    {toleranceStandards.map(it =>
                                        <option key={it} value={it}>{it}</option>
                                    )}
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td>Customer</td>
                            <td>
                                <input
                                    type='text'
                                    name='customer'
                                    value={protocol.customer ?? ''}
                                    onChange={handleTextChange}
                                    readOnly={isFormReadOnly}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>User</td>
                            <td>
                                <OperatorSelect
                                    operatorInitials={protocol.user ?? ''}
                                    isReadOnly={isFormReadOnly}
                                    onChange={(initials) => setProtocolField('user', initials)}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Comment</td>
                            <td>
                                <textarea
                                    name='comment'
                                    value={protocol.comment ?? ''}
                                    rows={3}
                                    onChange={handleTextChange}
                                    readOnly={isFormReadOnly}
                                />
                            </td>
                        </tr>


                        {protocol.protocolRevision && <tr>
                            <td>Protocol rev</td>
                            <td>
                                <input
                                    type='text'
                                    value={protocol.protocolRevision}
                                    disabled={true}
                                />
                            </td>
                        </tr>}
                        {protocol.createTime && <tr>
                            <td>Create time</td>
                            <td>
                                <input
                                    type='text'
                                    value={formatDatetime(protocol.createTime)}
                                    disabled={true}
                                />
                            </td>
                        </tr>}
                    </tbody>
                </table>

                {protocol.positions.length > 0 &&
                    <ProtocolFormPositions
                        isReadOnly={isFormReadOnly}
                    />
                }
                {(!isFormReadOnly && !protocol.positions.length) && <p>To add positions, right-click the pdf</p>}



                {(showReviewer || showReviewComment) &&
                    <table className='record-form--table' style={{ marginTop: '20px' }}>
                        <tbody>
                            {showReviewer && <tr>
                                <td>Reviewer</td>
                                <td>
                                    <OperatorSelect
                                        operatorInitials={mode === 'review' ? reviewer : latestReview?.operator ?? ''}
                                        isReadOnly={mode !== 'review'}
                                        onChange={(initials) => setReviewer(initials)}
                                    />
                                </td>
                            </tr>}
                            {showReviewComment && <tr>
                                <td>Comment</td>
                                <td>
                                    <textarea
                                        name='reviewComment'
                                        value={mode === 'review' ? reviewComment : latestReview?.comment ?? ''}
                                        rows={3}
                                        readOnly={mode !== 'review'}
                                        onChange={(e) => setReviewComment(e.target.value)}
                                    />
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                }


                <div style={{ marginTop: '20px' }}>
                    {mode === 'edit' && <>
                        <button type='submit'>Submit for review</button>
                        <button onClick={handleSaveDraft}>Save draft</button>
                    </>}
                    {mode === 'review' && <>
                        <button onClick={handleApproveClicked}>Approve</button>
                        <button onClick={handleRejectClicked}>Reject</button>
                    </>}
                    <button onClick={handleCloseClicked}>Close</button>
                    <span>{isSaving ? ' Saving...' : (!isDirty && !isFormReadOnly && protocol._id) && ' Changes saved'}</span>
                </div>
            </form>
        </div>
    );
}
