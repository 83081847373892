import { mapBy, maxBy, minBy, sortBy } from '../../utils/arrayUtils';
import { getGraphValue, getGraphValueAverage } from '../../pages/live-graphs/graphValueType';
import { DisplayOptions } from './displayOptions';
import { OeeRow } from '../../types/sharedTypeImpl';


export interface MainData {
    shift: string;
    shiftIndex: number;
    start: Date;
    end: Date;
    isSetup: boolean;
    isDisconnect: boolean;
    isCurrentProduct: boolean;
    weight: number;
    value: number;
    rollingAverageValue: number;
}

export interface ShiftData {
    shift: string;
    firstMainEntry: MainData;
    lastMainEntry: MainData;
    value: number;
    label: string;
}

// export interface Setup {
//     start: Date;
//     end: Date;
//     isSetup: boolean;
// }

export interface TransformedData {
    currentProduct: string;
    currentPartDisplayName: string;
    mainData: MainData[];
    shiftData: ShiftData[];
}

export function transformData(
    equipmentLiveData: OeeRow[],
    equipmentHourData: OeeRow[],
    equipmentShiftData: OeeRow[],
    displayOptions: DisplayOptions,
): TransformedData {
    const mainData = displayOptions.resolution === 'hour' ? equipmentHourData : equipmentLiveData;
    const { product, partDisplayName } = getCurrentProduct(mainData);
    const filteredMainData = filterInputRows(mainData, displayOptions);

    return {
        currentProduct: product,
        currentPartDisplayName: partDisplayName,
        mainData: getMainData(filteredMainData, product, displayOptions),
        shiftData: getShiftData(filteredMainData, equipmentShiftData, displayOptions),
        // setups: getSetups(equipmentLiveData),
    }
}

function filterInputRows(
    equipmentMainData: OeeRow[],
    displayOptions: DisplayOptions): OeeRow[] {
    const start = new Date(+new Date() - displayOptions.timeFrameDays * 24 * 3600_000);

    const filtered = equipmentMainData
        .filter(it => displayOptions.visibleShifts.includes(it.shift))
        .filter(it => !displayOptions.excludeWeekends || it.shiftName !== 'W')
        .filter(it => it.end >= start);
    const sorted = sortBy(filtered, it => it.start);
    return sorted;
}

function getCurrentProduct(equipmentMainData: OeeRow[]): { product: string, partDisplayName: string } {
    const latestRow = maxBy(equipmentMainData, it => +it.start);
    return {
        product: latestRow.product,
        partDisplayName: latestRow.partDisplayName,
    };
}

function getMainData(
    equipmentMainData: OeeRow[],
    currentProduct: string,
    displayOptions: DisplayOptions,
): MainData[] {
    const mainDataPartial = equipmentMainData.map(it => ({
        shift: it.shift,
        shiftIndex: getShiftIndex(it),
        start: it.start,
        end: it.end,
        // isSetup: it.connectedTime > 0 && (it.setupTime / it.connectedTime) > 0.5,
        isSetup: it.connectedTime > 0 && it.setupTime === it.connectedTime,
        isDisconnect: it.connectedTime == 0,
        isCurrentProduct: (it.product === currentProduct && it.product !== 'Mixed') || it.equipment === 'All equipments',
        weight: getGraphValue(it, displayOptions.valueType).weight,
        value: getGraphValue(it, displayOptions.valueType).value,
    }));

    sortBy(mainDataPartial, it => it.start);
    const mainData = smoothMainData(mainDataPartial, displayOptions);
    return mainData;
}

function smoothMainData(
    mainData: Omit<MainData, 'rollingAverageValue'>[],
    displayOptions: DisplayOptions,
): MainData[] {
    const amount = displayOptions.smoothingAmount;

    const smoothedData = mainData.map(it => ({ ...it, rollingAverageValue: 0 }));
    for (let i = 0; i < smoothedData.length; i++) {
        if (smoothedData[i].weight == 0) {
            smoothedData[i].rollingAverageValue = 0;
            continue;
        }

        let sum = 0;
        let count = 0;
        for (let j = i - amount; j <= i + amount; j++) {
            if (j < 0 || j >= smoothedData.length) continue;
            if (smoothedData[j].shiftIndex !== smoothedData[i].shiftIndex) continue;// Only consider the same shift
            sum += smoothedData[j].value * smoothedData[j].weight;
            count += smoothedData[j].weight;
        }
        smoothedData[i].rollingAverageValue = sum / count;
    }
    return smoothedData;
}

function getShiftData(
    equipmentMainData: OeeRow[],
    equipmentShiftData: OeeRow[],
    displayOptions: DisplayOptions,
): ShiftData[] {
    const mainDataByShiftIndex = mapBy(equipmentMainData, it => getShiftIndex(it));
    const shiftDataByShiftIndex = mapBy(equipmentShiftData, it => getShiftIndex(it));

    const shiftData = [];
    for (const shiftIndex of mainDataByShiftIndex.keys()) {
        const mainData = mainDataByShiftIndex.get(shiftIndex);
        const shiftRow = shiftDataByShiftIndex.get(shiftIndex)?.[0] ?? null;

        const shift = mainData[0].shift;
        const firstMainEntry = minBy(mainData, it => +it.start);
        const lastMainEntry = maxBy(mainData, it => +it.start);

        // Use the average of the main data, unless it is truncated. Else use the shift data.
        // Prefer using live data, since scrap data changes are appied sooner.
        const { value } = shiftRow == null || shiftRow.start > equipmentMainData[0].start
            ? getGraphValueAverage(mainData, displayOptions.valueType)
            : getGraphValue(shiftRow, displayOptions.valueType);

        shiftData.push({
            shift,
            firstMainEntry,
            lastMainEntry,
            value,
            label: value.toFixed(2),
        });
    }
    return shiftData;
}

function getShiftIndex(oeeRow: OeeRow): number {
    const shiftTypeIndex =
        oeeRow.shift === 'night' ? 0
            : oeeRow.shift === 'morning' ? 1
                : 2;

    const hoursOffset = oeeRow.shift === 'night' ? 12 : 0;
    const dayIndex = getUTCDayOfYear(oeeRow.start, hoursOffset);

    return 3 * dayIndex + shiftTypeIndex;
}

function getUTCDayOfYear(date: Date, plusHours: number): number {
    const dateFinal = new Date(date.getTime() + (plusHours ?? 0) * 3600 * 1000)
    const startOfYear = new Date(Date.UTC(dateFinal.getUTCFullYear(), 0, 1));
    const diff = dateFinal.getTime() - startOfYear.getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.floor(diff / oneDay);
}

// function getSetups(equipmentLiveData: OeeRow[]): Setup[] {
//     const rows = equipmentLiveData.map(it => ({
//         start: it.start,
//         end: it.end,
//         isSetup: it.connectedTime > 0 && (it.setupTime / it.connectedTime) > 0.5
//     }));

//     const setups = [];
//     let currentSetupStart = null;
//     for (const row of rows) {
//         if (row.isSetup) {
//             if (currentSetupStart == null) {
//                 currentSetupStart = row.start;
//             }
//         } else if (currentSetupStart != null) {
//             setups.push({
//                 start: currentSetupStart,
//                 end: row.start,
//             });
//             currentSetupStart = null;
//         }
//     }
//     if (currentSetupStart != null) {
//         setups.push({
//             start: currentSetupStart,
//             end: rows[rows.length - 1].end,
//         });
//     }

//     return setups;
// }
