import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import { dateToLabel, dateWithoutTime, mapBy, mapToArrayOfKeyVal, sortBy } from '../../utils/arrayUtils';
import { getGraphValue } from './graphValueType';
import useResizeObserver from '../../hooks/useResizeObserver';
import { getShiftColorLight } from '../../utils/liveGraphColors';
import { DisplayOptions } from '../../components/equipment-oee-graph/displayOptions';
import { OeeRow } from '../../types/sharedTypeImpl';


interface FactoryGraphProps {
    className?: string;
    shiftTable: OeeRow[];
    graphValueType: string;
    displayOptions: DisplayOptions;
}

export default function FactoryGraph({
    className,
    shiftTable,
    graphValueType,
    displayOptions,
}: FactoryGraphProps) {

    const containerRef = useRef<SVGSVGElement>();
    const dimensions = useResizeObserver(containerRef);

    useEffect(() => {
        const data = prepareData(shiftTable, graphValueType, displayOptions);
        drawGraph(containerRef.current, data, displayOptions);
    }, [shiftTable, graphValueType, dimensions, displayOptions]);


    return (
        <svg
            ref={containerRef}
            className={className}
        />
    );
}


function drawGraph(container: SVGSVGElement, data: any[], displayOptions: DisplayOptions) {
    const root = d3.select(container);
    root.html('');
    const rootRect = container.getBoundingClientRect();

    const margins = { top: 20, right: 10, bottom: 20, left: 10 };
    const width = rootRect.width - margins.left - margins.right;
    const height = rootRect.height - margins.top - margins.bottom;

    const xScale = d3.scaleBand()
        .domain(data.map(d => d.label))
        .range([0, width])
        .padding(0.07);
    const yScale = d3.scaleLinear()
        .domain([0, 1])
        .range([height, 0]);

    const graph = root.append('g')
        .attr('transform', `translate(${margins.left}, ${margins.top})`);

    // Append x-axis
    graph.append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(xScale));

    const barWidth = xScale.bandwidth() / displayOptions.visibleShifts.length;
    const textSize = Math.min(barWidth / 2, 18);
    data.forEach((day, dayIndex) => {
        day.shifts.forEach((shift, barIndex) => {
            graph.append('rect')
                .attr('x', xScale(day.label) + barWidth * barIndex)
                .attr('y', yScale(shift.value))
                .attr('height', height - yScale(shift.value))
                .attr('width', barWidth)
                .attr('fill', getShiftColorLight(shift.shift));

            graph.append('text')
                .attr('x', xScale(day.label) + barWidth * barIndex + barWidth / 2)
                .attr('y', yScale(shift.value) - 5)
                .attr('text-anchor', 'middle')
                .attr('font-size', `${textSize}px`)
                .text(shift.label);
        });
    });
}


function prepareData(shiftTable: OeeRow[], graphValueType: string, displayOptions: DisplayOptions) {
    const allEquipmentsRows = shiftTable
        .filter(it => it.equipment === 'All equipments')
        .filter(it => displayOptions.visibleShifts.includes(it.shift));
    const mapByDay = mapBy(allEquipmentsRows, it => dateWithoutTime(it.start).getTime());
    const days = mapToArrayOfKeyVal(mapByDay, 'dateMillis', 'shifts') as any[];

    sortBy(days, it => it.dateMillis);
    days.forEach(it => it.label = dateToLabel(new Date(it.dateMillis)));

    days.forEach(day => {
        sortBy(day.shifts, (it: any) => it.start);
        day.shifts = day.shifts.map(it => convertShift(it, graphValueType));
    });
    return days;
}

function convertShift(oeeRow, graphValueType) {
    const { value } = getGraphValue(oeeRow, graphValueType);
    return {
        shift: oeeRow.shift,
        value,
        label: value ? value.toFixed(2) : '0',
    };
}
