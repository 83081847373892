import { readFileAsString } from '../../utils/readFile';
import readKeyenceCmmFile from './readKeyenceCmmFile';
import { isMitutoyoCmmFile, readMitutoyoCmmFile } from './readMitutoyoCmmFile';
import readZeissCmmFile from './readZeissCmmFile';
import { ReadMeasurementsResult } from './types';


export default async function readCmmFile(file: File): Promise<ReadMeasurementsResult> {
    if (file.name.endsWith('.xlsx') || file.name.endsWith('.xlsm') || file.name.endsWith('.xls')) {
        return readZeissCmmFile(file);
    } else if (file.name.endsWith('.csv')) {
        const fileText = await readFileAsString(file, 'ISO-8859-1');
        if (isMitutoyoCmmFile(fileText)) {
            return readMitutoyoCmmFile(fileText);
        } else {
            return readKeyenceCmmFile(fileText);
        }
    } else {
        return { success: false, errorMessage: `Invalid file type` };
    }
}
