/**
 * Handles reporting front-end errors to an external monitoring service.
 * */

import * as Sentry from '@sentry/react';


export function initErrorReporting() {
    if (import.meta.env.VITE_ENV === 'development') return;

    Sentry.init({
        dsn: 'https://1f0b0856835eadfe22d7e1e0b1bccc90@o4506341886590976.ingest.sentry.io/4506341892096000',

        environment: import.meta.env.VITE_ENV,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // tracesSampleRate: 1.0,

        // Capture Replays of sessions with an error
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,

        // Make sure that adblockers don't block the Sentry script, by routing requests through the back-end
        tunnel: '/api/v1/sentry-proxy',
    });
}
