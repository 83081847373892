import { useQuery } from '@tanstack/react-query';
import { Outlet, Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import '../css/style.scss';
import { fetchIsAdmin, fetchIsLoggedIn } from './api/requests';
import BrokenTools from './pages/broken-tools/brokenTools';
import Index from './pages/index';
import LiveGraphs from './pages/live-graphs/liveGraphs';
import Login from './pages/login/login';
import MachineLog from './pages/machine-log/machineLog';
import ManagementView from './pages/management-view/managementView';
import MeasurementInterval from './pages/measurement-interval/measurementInterval';
import Overlook from './pages/overlook/overlook';
import PotentialScraps from './pages/potential-scraps/potentialScraps';
import ProtocolList from './pages/protocol-list/protocolList';
import RecordList from './pages/record-list/recordList';
import RecordView from './pages/record-view/recordView';
import ProtocolView from './pages/protocol-view/protocolView';
import { ProtocolFormProvider } from './pages/protocol-view/protocolFormContext';
import Operators from './pages/operators/operators';


export default function App() {

    const { data: isLoggedIn } = useQuery({
        queryKey: ['isLoggedIn'],
        queryFn: async () => await fetchIsLoggedIn(),
    });

    const { data: isAdmin } = useQuery({
        queryKey: ['isAdmin'],
        queryFn: async () => await fetchIsAdmin(),
        enabled: isLoggedIn,
        refetchOnWindowFocus: false,
    });

    return (
        <Router>
            <Routes>
                <Route path='/login' element={<Login />} />

                <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
                    <Route path='/' element={<Index />} />
                    <Route path='/measurement-interval' element={<MeasurementInterval />} />
                    <Route path='/machine-log' element={<MachineLog />} />
                    <Route path='/overlook' element={<Overlook />} />
                    <Route path='/potential-scraps' element={<PotentialScraps />} />
                    <Route path='/management-view' element={<ManagementView />} />
                    <Route path='/live-graphs' element={<LiveGraphs />} />
                    <Route path='/broken-tools' element={<BrokenTools />} />
                    <Route path='/record-list' element={<RecordList />} />
                    <Route path='/record-view' element={<RecordView />} />

                    <Route element={<AdminRoute isAdmin={isLoggedIn && isAdmin} />}>
                        <Route path='/protocol-list' element={<ProtocolList />} />
                        <Route path='/protocol-view' element={
                            <ProtocolFormProvider>
                                <ProtocolView />
                            </ProtocolFormProvider>
                        } />
                        <Route path='/operators' element={<Operators />} />
                    </Route>
                </Route>

                <Route path='*' element={<NotFound />} />
            </Routes>
        </Router>
    );
}


function ProtectedRoute({ isLoggedIn }: { isLoggedIn: boolean | null }) {
    const navigate = useNavigate();

    if (isLoggedIn == null) {
        return <p>Loading...</p>;
    } else if (isLoggedIn === false) {
        navigate('/login');
        return null;
    } else {
        return <Outlet />;
    }
}

function AdminRoute({ isAdmin }: { isAdmin: boolean | null }) {
    if (isAdmin == null) {
        return <p>Loading...</p>;
    } else if (isAdmin === false) {
        return <AccessDenied />;
    } else {
        return <Outlet />;
    }
}


function NotFound() {
    return <h1>Page Not Found</h1>;
}

function AccessDenied() {
    return <h1>Access Denied</h1>;
}
