import { useCallback, useEffect, useRef } from 'react';
import { Size } from '../../types';

// eslint-disable-next-line import/no-unresolved
import workerSrc from 'pdfjs-dist/build/pdf.worker.min.mjs?url';

let pdfjsLib;
async function loadPdfJs() {
    if (!pdfjsLib) {
        pdfjsLib = await import('pdfjs-dist');
        pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;
    }
    return pdfjsLib;
}


interface UseRenderPdfProps {
    canvasRef: React.RefObject<HTMLCanvasElement>;
    pdfContent: Uint8Array | null;
    page: number;
    scale: number;
    onPageCountLoaded: (pageCount: number) => void;
    onPageSizeLoaded: (size: Size) => void;
    onRendered: (page: number, size: Size) => void;
}

export default function useRenderPdf({
    canvasRef,
    pdfContent,
    page,
    scale,
    onPageCountLoaded,
    onPageSizeLoaded,
    onRendered,
}: UseRenderPdfProps) {
    const onscreenCanvas = canvasRef;
    const offscreenCanvas = useRef<HTMLCanvasElement>(document.createElement('canvas'));
    const pdfDoc = useRef(null);
    const pdfPage = useRef(null);
    const renderedPdfContent = useRef(null);
    const renderedPageNum = useRef(0);
    const renderedScale = useRef(0);
    const renderTask = useRef(null);


    const memoizedRender = useCallback((
        pdfContent: Uint8Array | null,
        page: number,
        scale: number,
    ): { abort: () => void } => {
        function render(): { abort: () => void } {
            const abortController = new AbortController();

            const loadPdf = async () => {
                if (!pdfContent) return;

                let triggered = false;
                if (pdfContent !== renderedPdfContent.current) {
                    await loadDocument(pdfContent, abortController.signal);
                    triggered = true;
                }
                if (triggered || page !== renderedPageNum.current) {
                    await loadPage(page, abortController.signal);
                    triggered = true;
                }
                if ((triggered || scale !== renderedScale.current) && scale) {
                    await renderPage(page, scale, abortController.signal);
                    triggered = true;
                }
            };
            loadPdf();

            return {
                abort: () => {
                    abortController.abort();
                    // renderTask.current?.cancel();
                }
            };
        }

        async function loadDocument(pdfContent: Uint8Array, signal: AbortSignal) {
            const pdfjsLib = await loadPdfJs();
            pdfDoc.current = await pdfjsLib.getDocument({ data: pdfContent }).promise;
            renderedPdfContent.current = pdfContent;

            onPageCountLoaded(pdfDoc.current.numPages);
        }

        async function loadPage(page: number, signal: AbortSignal) {
            pdfPage.current = await pdfDoc.current.getPage(page);
            renderedPageNum.current = page;

            const viewport = pdfPage.current.getViewport({ scale: 1 });
            onPageSizeLoaded({ width: viewport.width, height: viewport.height });
        }

        async function renderPage(page: number, scale: number, signal: AbortSignal) {
            await renderTask.current?.cancel();

            //Render pdf offscreen (to prevent flashing)
            const viewport = pdfPage.current.getViewport({ scale });
            offscreenCanvas.current.width = viewport.width;
            offscreenCanvas.current.height = viewport.height;
            const renderContext = {
                viewport,
                canvasContext: offscreenCanvas.current.getContext('2d'),
            };

            // Await the render while allowing it to be cancelled
            renderTask.current = pdfPage.current.render(renderContext);
            try {
                await renderTask.current.promise;
            } catch (error) {
                if (error.name === 'RenderingCancelledException') {
                    return;
                } else {
                    throw error;
                }
            } finally {
                renderTask.current = null;
            }

            // Copy to the on-screen canvas
            const onscreenContext = onscreenCanvas.current.getContext('2d');
            onscreenCanvas.current.width = viewport.width;
            onscreenCanvas.current.height = viewport.height;
            onscreenContext.drawImage(offscreenCanvas.current, 0, 0);

            onRendered(page, { width: viewport.width, height: viewport.height });
        }

        return render();
    }, [onPageCountLoaded, onPageSizeLoaded, onRendered, onscreenCanvas, offscreenCanvas, pdfDoc, pdfPage, renderedPdfContent, renderedPageNum, renderedScale, renderTask]);

    useEffect(() => {
        const { abort } = memoizedRender(pdfContent, page, scale);
        return abort;
    }, [pdfContent, page, scale, memoizedRender]);
}
