import { ContextMenu, MenuItem } from '../../components/contextMenu';
import { Point } from '../../types';


export interface FormPositionMenuState {
    activePositionIdx: number;
    screenCoords: Point;
}

interface FormPositionMenuProps {
    activePositionIdx: number;
    screenCoords: Point;
    onSelectSetDescription: (activePositionIdx: number) => void;
    onSelectDelete: (activePositionIdx: number) => void;
    onClose: () => void;
}

export default function FormPositionMenu({
    activePositionIdx,
    screenCoords,
    onSelectSetDescription,
    onSelectDelete,
    onClose,
}: FormPositionMenuProps) {

    const menuItems: MenuItem[] = [
        {
            text: 'Set description',
            callback: () => onSelectSetDescription(activePositionIdx),
        },
        {
            text: 'Delete position',
            callback: () => onSelectDelete(activePositionIdx),
        },
    ];

    return (
        <ContextMenu
            isOpen={true}
            position={screenCoords}
            items={menuItems}
            close={onClose}
        />
    );

}
