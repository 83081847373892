import { useEffect, useState } from 'react';
import { fetchCurrentSession, fetchEquipments, fetchPartNames } from '../../api/requests';
import useFetchOnce from '../../hooks/useFetchOnce';
import { sortBy } from '../../utils/arrayUtils';
import MaintenanceLogDialog from './maintenanceLogDialog';


interface SessionControlBarProps {
    selectedEquipment: string | null;
    setSelectedEquipment: (equipment: string | null) => void;
    selectedPartName: string | null;
    setSelectedPartName: (product: string | null) => void;
}

export default function SessionControlBar({
    selectedEquipment,
    setSelectedEquipment,
    selectedPartName,
    setSelectedPartName,
}: SessionControlBarProps) {

    const [partNameInput, setPartNameInput] = useState<string>('');
    const [isMaintenanceLogDialogOpen, setIsMaintenanceLogDialogOpen] = useState(false);


    const { data: equipments } = useFetchOnce({
        queryKey: ['equipments'],
        queryFn: () => fetchEquipments(),
        select: (equipments) => sortBy([...equipments], it => it.displayName),
        refetchOnWindowFocus: false,
    });

    const { data: products } = useFetchOnce({
        queryKey: ['part-names'],
        queryFn: () => fetchPartNames(),
        select: (products) => [...products.sort()],
        refetchOnWindowFocus: false,
    });

    //Set selected equipment or product from url hash
    useEffect(() => {
        if (!equipments) return;

        const hashParams = new URLSearchParams(window.location.hash.substring(1));
        const equipment = hashParams.get('equipment');
        const partName = hashParams.get('partName');
        if (equipment) {
            setSelectedEquipment(equipment);
        } else if (partName) {
            setSelectedEquipment(null);
            setSelectedPartName(partName);
        }
    }, [equipments, setSelectedEquipment, setSelectedPartName]);

    // On equipment change
    useEffect(() => {
        if (!selectedEquipment) return;
        (async () => {
            const currentSession = await fetchCurrentSession(selectedEquipment);
            setSelectedPartName(currentSession.partName);
        })();
    }, [selectedEquipment, setSelectedPartName]);

    // On product change
    useEffect(() => {
        setPartNameInput(selectedPartName ?? '');
    }, [selectedPartName]);


    const handleEquipmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedEquipment(e.target.value);
        setUrlHash('equipment=' + e.target.value);
    }

    const handlePartNameSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSelectedPartName(partNameInput);
        setSelectedEquipment(null);
        setUrlHash('partName=' + partNameInput);
    }

    const handleMaintenanceLogClick = () => {
        setIsMaintenanceLogDialogOpen(true);
    }

    const handleGuideClick = () => {
        const baseUrl = 'https://berryglade.sharepoint.com/sites/BGNET/Sorvikohtaiset%20ohjeet/';
        const url = baseUrl + encodeURIComponent(selectedEquipment!);
        window.open(url, '_blank');
    }

    const setUrlHash = (hash: string) => {
        history.replaceState(null, null, document.location.pathname + '#' + hash);
    }


    return (
        <div style={{ display: 'flex', gap: '4px' }}>
            <select
                style={{width: '180px'}}
                value={selectedEquipment || ''}
                onChange={handleEquipmentChange}
            >
                <option value={''} disabled hidden />
                {equipments?.map(equipment => (
                    <option key={equipment.id} value={equipment.id}>{equipment.displayName}</option>
                ))}
            </select>

            <form id='productForm' onSubmit={handlePartNameSubmit}>
                <input
                    type='text'
                    id='product'
                    name='product'
                    list='products'
                    autoComplete='off'
                    required
                    value={partNameInput}
                    onChange={(e) => setPartNameInput(e.target.value)}
                />
                <datalist id='products'>
                    {products?.map(product => (
                        <option key={product} value={product} />
                    ))}
                </datalist>
                <input id='tenp' type='submit' value='Submit' />
            </form>
            <button onClick={handleMaintenanceLogClick} disabled={selectedEquipment == null}>Maintenance log</button>
            <button onClick={handleGuideClick} disabled={selectedEquipment == null}>Guide</button>

            <MaintenanceLogDialog
                isOpen={isMaintenanceLogDialogOpen}
                equipment={selectedEquipment}
                onClose={() => setIsMaintenanceLogDialogOpen(false)}
            />
        </div>
    )
}
