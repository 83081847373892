import { useEffect, useState } from 'react';
import { setProductComment } from '../../api/requests';
import ModalDialog from '../../components/modalDialog';


interface ProductCommentDialogProps {
    isOpen: boolean;
    product?: string;
    initialProductComment?: string;
    onSubmited: () => void;
    onClose: () => void;
}

export default function ProductCommentDialog({
    isOpen,
    product,
    initialProductComment,
    onSubmited,
    onClose,
}: ProductCommentDialogProps) {

    const [input, setInput] = useState('');

    useEffect(() => {
        setInput(initialProductComment ?? '');
    }, [initialProductComment]);


    const handleSubmit = async () => {
        await setProductComment(product, input);
        onSubmited();
        onClose();
    }


    if (!isOpen) return null;
    return (
        <ModalDialog
            isOpen={isOpen}
            frameClassName='modal-frame--fixed_width'
            contentClassName='modal-content--tablet'
            onCancel={onClose}
        >
            <textarea id='productCommentEditText' value={input} onChange={(e) => setInput(e.target.value)} />
            <div className='dialog--button-bar'>
                <button className='button--large' onClick={handleSubmit}>Submit</button>
            </div>
        </ModalDialog>
    )
}
