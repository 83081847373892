
export enum EntityTypes {
    SESSION = 'session',
    OPERATION = 'operation',
    EQUIPMENT = 'equipment',
    PROTOCOL = 'protocol',
    RECORD = 'record',
    RECORD_WITH_PROTOCOL = 'record_with_protocol',
    PROTOCOL_WITH_RECORD = 'protocol_with_record',
    CALIBRATED_TOOL = 'calibrated_tool',
    OVERLOOK = 'overlook_tool',
    OEE = 'oee',
    DIAGNOSTICS = 'diagnostics',
    MEASUREMENT_INTERVAL_ROW = 'measurement_interval_row',
    MAINTENANCE_OPERATION = 'maintenance_operation',
    MACHINE_LOG_KPI = 'machine_log_kpi',
    MANAGEMENT_VIEW_KPI = 'management_view_kpi',
}

const dateFields = {
    [EntityTypes.SESSION]: ['start', 'end'],
    [EntityTypes.OPERATION]: ['time'],
    [EntityTypes.EQUIPMENT]: [],
    [EntityTypes.PROTOCOL]: ['createTime'],
    [EntityTypes.RECORD]: ['createTime', 'sessionStart', 'measurementStart'],
    [EntityTypes.RECORD_WITH_PROTOCOL]: ['createTime', 'sessionStart', 'measurementStart'],
    [EntityTypes.PROTOCOL_WITH_RECORD]: ['createTime', 'sessionStart', 'measurementStart'],
    [EntityTypes.CALIBRATED_TOOL]: [],
    [EntityTypes.OVERLOOK]: ['start', 'end', 'latestUpdate', 'sessionEndPrediction'],
    [EntityTypes.OEE]: ['start', 'end'],
    [EntityTypes.DIAGNOSTICS]: ['serverTime', 'latestFullUpdate', 'latestFastUpdate'],
    [EntityTypes.MEASUREMENT_INTERVAL_ROW]: [
        'lastMeasureTime', 'sessionStart', 'setupEndConfirmTime', 'disruptionStatusStart',
        'intervalPrediction', 'sessionEndPrediction',
    ],
    [EntityTypes.MAINTENANCE_OPERATION]: ['time'],
    [EntityTypes.MACHINE_LOG_KPI]: ['sessionStart', 'sessionEnd', 'setupEnd'],
    [EntityTypes.MANAGEMENT_VIEW_KPI]: ['sessionStart'],
};


export async function getJson(
    url: string,
    params?: Record<string, QueryParam>,
    entityType?: EntityTypes,
    abortController?: AbortController,
) {
    const finalUrl = url + encodeQueryData(params);
    return fetch(finalUrl, { method: 'GET', signal: abortController?.signal })
        .then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const parsedData = convertSpecificFieldsToDate(data, dateFields[entityType]);
                return parsedData;
            } else {
                console.log(`Request failed: ${response}`);
                throw new Error('Request failed.');
            }
        });
}

export async function getJsonWithRedirect(url: string, params: Record<string, QueryParam>, entityType?: EntityTypes) {
    const finalUrl = url + encodeQueryData(params);
    return fetch(finalUrl, { method: 'GET' })
        .then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                const parsedData = convertSpecificFieldsToDate(data, dateFields[entityType]);
                return parsedData;
            } else if (response.status === 401) {
                console.log(`Request unauthorized: ${response}`);
                window.location.href = '/login';
                throw new Error('Unauthorized');
            } else {
                console.log(`Request failed: ${response}`);
                throw new Error('Request failed.');
            }
        });
}

export async function getTextWithRedirect(url: string, params: Record<string, QueryParam>) {
    const finalUrl = url + encodeQueryData(params);
    return fetch(finalUrl, { method: 'GET' })
        .then(async (response) => {
            if (response.ok) {
                const data = await response.text();
                return data;
            } else if (response.status === 401) {
                console.log(`Request unauthorized: ${response}`);
                window.location.href = '/login';
                throw new Error('Unauthorized');
            } else {
                console.log(`Request failed: ${response}`);
                throw new Error('Request failed.');
            }
        });
}

export async function postJson(url: string, data?: object): Promise<Response> {
    return await sendJson(url, data, 'POST');
}

export async function putJson(url: string, data: object): Promise<Response> {
    return await sendJson(url, data, 'PUT');
}

export async function patchJson(url: string, data: object): Promise<Response> {
    return await sendJson(url, data, 'PATCH');
}

export async function deleteJson(url: string, data?: object): Promise<Response> {
    return await sendJson(url, data, 'DELETE');
}


async function sendJson(url: string, data: object, method: 'POST' | 'PUT' | 'PATCH' | 'DELETE'): Promise<Response> {
    return await fetch(url, {
        method,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        ...(data && { body: JSON.stringify(data) }),
    })
        .catch((error) => {
            console.log(error);
            throw error;
        });
}

export async function postFileAndData(
    url: string,
    blobData: RequestBlobEntry | RequestBlobEntry[],
    objectData: RequestObjectEntry | RequestObjectEntry[]
): Promise<Response> {
    return await sendFileAndData(url, 'POST', blobData, objectData);
}

export async function sendFileAndData(
    url: string,
    method: 'POST' | 'PUT',
    blobData: RequestBlobEntry | RequestBlobEntry[],
    objectData: RequestObjectEntry | RequestObjectEntry[]
): Promise<Response> {
    const formData = new FormData();

    //Append files
    if (Array.isArray(blobData)) {
        for (const entry of blobData) {
            appendFiles(formData, entry.label, entry.blob);
        }
    } else {
        appendFiles(formData, blobData.label, blobData.blob);
    }

    //Append object data
    if (Array.isArray(objectData)) {
        for (const entry of objectData) {
            formData.append(entry.label, JSON.stringify(entry.obj));
        }
    } else {
        formData.append(objectData.label, JSON.stringify(objectData.obj));
    }

    //Post
    try {
        return await fetch(url, {
            method,
            body: formData,
        });
    } catch (error) {
        console.error('Error during file upload', error);
        throw error;
    }
}


function appendFiles(formData: FormData, label: string, files: Blob | Blob[]) {
    if (Array.isArray(files)) {
        for (const file of files) {
            formData.append(label, file);
        }
    } else {
        formData.append(label, files);
    }
}


/**
 *  Convert fields to Date type if they are in the list. Original implementation
 */
function convertSpecificFieldsToDate(obj: any, dateFieldList: string[]) {
    if (obj == null) return obj;
    if (dateFieldList == null) return obj;

    if (Array.isArray(obj)) {
        return obj.map(item => convertSpecificFieldsToDate(item, dateFieldList));
    }
    if (typeof obj === 'object') {
        for (const [key, value] of Object.entries(obj)) {
            if (dateFieldList.includes(key) && typeof value === 'string') {
                obj[key] = new Date(value);
            } else if (typeof value === 'object') {
                obj[key] = convertSpecificFieldsToDate(value, dateFieldList);
            }
        }
    }
    return obj;
}

/**
 *  Convert fields to Date type if they are in the list. Alternative type safe method
 */
export function convertFieldsToDateTyped<T extends Record<string, any> | Record<string, any>[] | null>(
    obj: any,
    dateFieldList: Array<keyof T>,
): T {
    if (obj == null) return obj;
    if (dateFieldList == null || dateFieldList.length === 0) return obj;
    const dateFieldStrings = dateFieldList as string[];

    if (Array.isArray(obj)) {
        return obj.map(item => convertFieldsToDateTyped(item, dateFieldList)) as T;
    }
    if (typeof obj === 'object') {
        for (const [key, value] of Object.entries(obj)) {
            if (dateFieldStrings.includes(key) && typeof value === 'string') {
                obj[key] = new Date(value);
            } else if (typeof value === 'object') {
                obj[key] = convertFieldsToDateTyped(value, dateFieldList);
            }
        }
    }
    return obj;
}

export function encodeQueryData(data: Record<string, QueryParam>) {
    if (data == null) return '';

    const ret = [];
    for (const key in data) {
        const value = data[key];
        if (typeof value !== 'undefined' && value !== null) {
            ret.push(encodeQueryComponent(key) + '=' + encodeQueryComponent(value));
        }
    }
    return '?' + ret.join('&');
}

export function encodeQueryComponent(value: QueryParam) {
    const valueConverted = value instanceof Date ? value.toISOString() : value;
    const encoded = encodeURIComponent(valueConverted);
    return encoded;
}


type QueryParam = string | number | boolean | Date | null
// type ReturnValue = string | number | boolean | null

export interface RequestBlobEntry {
    label: string;
    blob: Blob | Blob[];
}

export interface RequestObjectEntry {
    label: string;
    obj: object;
}
