import { useRef, useState } from 'react';
import NavBar from '../../components/navBar';
import FilterableOperationTable from '../../components/operation-table/filterableOperationTable';
import ProductComment from './productComment';
import SessionControlBar from './sessionControlBar';
import SessionsTable, { SessionsTableRef } from './sessionsTable';
import { Session } from '../../types/sharedTypeImpl';


export default function MachineLog() {
    const sessionHistoryTableRef = useRef<SessionsTableRef>();

    const [selectedEquipment, setSelectedEquipment] = useState<string | null>(null);
    const [selectedPartName, setSelectedPartName] = useState<string | null>(null);
    const [selectedSession, setSelectedSession] = useState<Session | null>(null);


    return (
        <>
            <NavBar />
            <div className='content body--tablet'>
                <SessionControlBar
                    selectedEquipment={selectedEquipment}
                    setSelectedEquipment={setSelectedEquipment}
                    selectedPartName={selectedPartName}
                    setSelectedPartName={setSelectedPartName}
                />
                <ProductComment selectedPartName={selectedPartName} />
                <SessionsTable
                    ref={sessionHistoryTableRef}
                    selectedEquipment={selectedEquipment}
                    selectedPartName={selectedPartName}
                    selectedSession={selectedSession}
                    setSelectedSession={setSelectedSession}
                />
                <FilterableOperationTable
                    session={selectedSession}
                    selectedEquipment={selectedEquipment}
                    onOperationSaved={() => sessionHistoryTableRef.current?.refetchCurrentSessionKpis()}
                />
            </div>
        </>
    )
}
