import { dateWithoutTime } from '../utils/arrayUtils';
import { EntityTypes, convertFieldsToDateTyped, deleteJson, encodeQueryData, getJson, patchJson, postJson, putJson, sendFileAndData } from '../utils/fetchRequests';
import { DiagnosticsData } from '../pages/index/diagnosticsData';
import { ProtocolImageEntry } from '../types';
import { MaintenanceOperation, Measurement, MeasurementIntervalState, MeasurementUnsaved, NewOperation, OeeRow, Operation, Operator, ProgramNameMapping, Protocol, ProtocolWithRecord, Record, RecordDraft, RecordWithProtocol, Session } from '../types/sharedTypeImpl';
import { ManagementViewKpis } from '../../../shared/types/managementViewKpi';
import { Equipment } from '../../../shared/types/mtlinkiTypes';
import { OverlookEntry } from '../../../shared/types/overlookEntry';
import { MachineLogKpi } from '../../../shared/types/machineLogKpi';
import { CalibratedTool } from '../../../shared/types/calibratedTool';


export async function fetchIsLoggedIn(): Promise<boolean> {
    const isAdmin = await getJson(`/api/v1/users/isLoggedIn`);
    return isAdmin;
}

export async function logout(): Promise<string> {
    const response = await postJson(`/api/v1/logout`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const responseBody = await response.json();
    return responseBody.redirectUrl;
}

export async function fetchIsAdmin(): Promise<boolean> {
    const isAdmin = await getJson(`/api/v1/users/isAdmin`);
    return isAdmin;
}

export async function fetchAppVersion(): Promise<string> {
    const version = await getJson(`/api/v1/app-version`);
    return version;
}

export async function fetchEquipments(): Promise<Equipment[]> {
    const equipments = await getJson(`/api/v1/equipments`);
    return equipments;
}

export async function fetchPartNames(): Promise<string[]> {
    const products = await getJson(`/api/v1/products/part-names`);
    return products;
}


export async function fetchProductComment(partName: string): Promise<string> {
    const comment = await getJson(`/api/v1/products/comment/`, { partName });
    return comment;
}

export async function setProductComment(partName: string, productComment: string): Promise<void> {
    const response = await putJson(`/api/v1/products/comment`, {
        partName,
        productComment,
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetchSessions(partName: string): Promise<Session[]> {
    const sessions = await getJson(`/api/v1/sessions`, { partName }, EntityTypes.SESSION);
    return sessions;
}

export async function fetchCurrentSession(equipment: string): Promise<Session> {
    const session = await getJson(`/api/v1/equipments/${equipment}/current-session`, null, EntityTypes.SESSION);
    return session;
}

export async function fetchCurrentSessions(): Promise<Session[]> {
    const session = await getJson(`/api/v1/equipments/current-sessions`, null, EntityTypes.SESSION);
    return session;
}

export async function postBatchDetails(
    equipment: string,
    product: string,
    sessionStart: Date,
    orderNumber: string,
    targetQuantity: number | null,
): Promise<void> {
    const data = { product, sessionStart, orderNumber, targetQuantity };
    const response = await postJson(`/api/v1/equipments/${equipment}/current-batch-details`, data);
    if (!response.ok) {
        const text = await response.text();
        throw new Error(text ?? '');
    }
}

export async function postMeasurement(
    measurement: MeasurementUnsaved
): Promise<{ measurement: Measurement, record: Record | undefined }> {
    const response = await postJson('/api/v1/measurement-intervals/measurements', measurement);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const resultMeasurement = convertFieldsToDateTyped<Measurement>(result.measurement, ['sessionStart', 'time']);
    const resultRecord = result.record
        ? convertFieldsToDateTyped<RecordDraft>(result.record, ['sessionStart', 'measurementStart', 'measurementEnd'])
        : null;
    return { measurement: resultMeasurement, record: resultRecord };
}

export async function fetchProtocols(): Promise<Protocol[]> {
    const protocols = await getJson(`/api/v1/protocols/`, null, EntityTypes.PROTOCOL);
    return protocols;
}

export async function fetchProtocol(id: string): Promise<Protocol> {
    const protocol = await getJson(`/api/v1/protocols/${id}`, null, EntityTypes.PROTOCOL);
    return protocol;
}

export async function upsertProtocol(
    protocol: Protocol,
    pdf: File | Uint8Array | null,
    imageSet: ProtocolImageEntry[],
): Promise<Protocol> {
    const images: Blob[] = [];
    const imageIndices: { fileIndex: number, positionIndex: number }[] = [];
    for (const [index, imageEntry] of imageSet.entries()) {
        images.push(imageEntry.image);
        imageIndices.push({ fileIndex: index, positionIndex: imageEntry.positionIdx })
    }

    const pdfData = pdf ? { label: 'pdf', blob: new Blob([pdf], { type: 'application/pdf' }), } : null;
    const imagesData = { label: 'images', blob: images, };
    const protocolData = { label: 'protocol', obj: protocol, }
    const imageIndexData = { label: 'imageIndices', obj: imageIndices };

    const path = protocol._id ? `api/v1/protocols/${protocol._id}` : 'api/v1/protocols';
    const method = protocol._id ? 'PUT' : 'POST';
    const files = [pdfData, imagesData].filter(it => it != null);
    const response = await sendFileAndData(path, method, files, [protocolData, imageIndexData]);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const rawObject = await response.json();
    return convertFieldsToDateTyped<Protocol>(
        rawObject,
        ['createTime']
    );
}

export async function patchProtocol(id: string, protocol: Partial<Protocol>): Promise<boolean> {
    const response = await patchJson(`/api/v1/protocols/${id}`, protocol);
    return response.ok && response.status === 200;
}

export async function fetchProtocolPdf(id: string): Promise<Uint8Array | null> {
    const response = await fetch(`/api/v1/protocols/${id}/pdf`);
    if (response.ok) {
        const buffer = await response.arrayBuffer();
        return new Uint8Array(buffer);
    } else {
        return null;
    }
}

export async function fetchVisualInspectionImage(imageFileName: string): Promise<Uint8Array> {
    const response = await fetch(`/api/v1/protocols/positions/images/${imageFileName}`);
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
}


export async function fetchRecords(filters?: {
    protocolId?: string,
    equipment?: string,
    search?: string,
    isDraft?: boolean,
    orderNumber?: string,
    onlyWithIssues?: boolean,
    limit?: number
}): Promise<Record[]> {
    const records = await getJson(
        `/api/v1/records`,
        { ...filters },
        EntityTypes.RECORD);
    return records;
}

export async function fetchRecordsWithProtocol(filters?: {
    protocolId?: string,
    equipment?: string,
    search?: string,
    isDraft?: boolean,
    orderNumber?: string,
    limit?: number
}): Promise<RecordWithProtocol[]> {
    const records = await getJson(
        `/api/v1/records`,
        { ...filters, include: 'protocol' },
        EntityTypes.RECORD_WITH_PROTOCOL);
    return records;
}

/* Start and end refer to the measurement start time. Start is inclusive and end is exclusive.
Results are returned ordered according to measurement start time in descending order. */
export async function fetchRecordsByProtocol(
    protocolId: string,
    equipment: string,
    start: Date | null,
    end: Date | null,
    limit: number
): Promise<RecordWithProtocol[]> {
    const records = await getJson(
        `/api/v1/records/by-protocol/${protocolId}`,
        {
            equipment,
            ...(start && { start }),
            ...(end && { end }),
            ...(limit && { limit }),
        },
        EntityTypes.RECORD_WITH_PROTOCOL);
    return records;
}

export async function fetchMeasurementProtocols(
    product: string | undefined,
    equipment: string | undefined,
): Promise<ProtocolWithRecord[]> {
    const protocols = await getJson(
        `/api/v1/protocols`,
        {
            equipment,
            product,
            status: 'approved',
            isNotDisabled: true,
            include: 'latestRecord',
        },
        EntityTypes.PROTOCOL_WITH_RECORD);
    return protocols;
}

export async function fetchRecord(id: string): Promise<Record> {
    const record = await getJson(`/api/v1/records/${id}`, null, EntityTypes.RECORD);
    return record;
}

export async function fetchLatestRecord(filters: {
    equipment: string,
    sessionStart: Date,
}): Promise<Record> {
    const record = await getJson(`/api/v1/records/latest`, filters, EntityTypes.RECORD);
    return record;
}


export async function upsertRecord(record: Record | RecordDraft): Promise<Response> {
    const response = record._id
        ? await putJson(`api/v1/records/${record._id}`, record)
        : await postJson('api/v1/records', record);

    if (!response.ok) {
        const text = await response.text();
        throw new Error(text ?? '');
    }
    return response;
}

export async function deleteRecordWithRelated(record: Record | RecordDraft): Promise<void> {
    const response = await deleteJson(`api/v1/records/${record._id}/with-related`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function fetchProgramNameMappings(): Promise<ProgramNameMapping[]> {
    const mappings = await getJson(`/api/v1/program-name-mappings`);
    return mappings;
}

export async function fetchCalibratedTools(): Promise<CalibratedTool[]> {
    const protocols = await getJson(`/api/v1/calibratedTools/`, null, EntityTypes.PROTOCOL);
    return protocols;
}

export async function fetchOverlookTable(): Promise<OverlookEntry[] | null> {
    const version = await getJson(`/api/v1/overlook`, null, EntityTypes.OVERLOOK);
    return version;
}


export async function fetchOperations(filters: {
    equipment?: string,
    product?: string,
    start?: Date,
    end?: Date,
    hasPendingPotentialScrap?: boolean,
    hasScrapCloseForm?: boolean,
    minScrapCloseTime?: Date,
}): Promise<Operation[] | null> {
    const version = await getJson(`/api/v1/operations`, filters, EntityTypes.OPERATION);
    return version;
}

export async function postOperation(operation: NewOperation): Promise<Operation> {
    const response = await postJson('/api/v1/operations', operation);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const returnOperation = convertFieldsToDateTyped<Operation>(result, ['time']);
    return returnOperation;
}

export async function updateOperation(operation: Operation): Promise<Operation> {
    const response = await patchJson(`/api/v1/operations/${operation._id}`, operation);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const returnOperation = convertFieldsToDateTyped<Operation>(result, ['time']);
    return returnOperation;
}


export async function fetchMaintenceOperations(equipment: string): Promise<MaintenanceOperation[]> {
    const operations = await getJson('/api/v1/operations/maintenance', { equipment }, EntityTypes.MAINTENANCE_OPERATION);
    return operations;
}

export async function postMaintenanceOperation(
    operation: Pick<MaintenanceOperation, 'equipment' | 'comment' | 'operator'>
): Promise<void> {
    const response = await postJson('/api/v1/operations/maintenance', operation);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetcMeasurementIntervalTable(): Promise<MeasurementIntervalState[] | null> {
    const version = await getJson(`/api/v1/measurement-intervals`, null, EntityTypes.MEASUREMENT_INTERVAL_ROW);
    return version;
}

export async function fetchRecordsReportCsv(
    orderNumber: string,
): Promise<Blob | null> {
    const response = await fetch(`/api/v1/records/report` + encodeQueryData({ orderNumber }));
    if (response.ok) {
        return await response.blob();
    } else {
        return null;
    }
}

export async function fetchSessionKpis(
    session: Session,
    abortController: AbortController
): Promise<MachineLogKpi> {
    const kpi = await getJson(`/api/v1/machine-log-kpis`, {
        equipment: session.equipment,
        product: session.product,
        start: session.start,
        ...(session.end && { end: session.end }),
    }, EntityTypes.MACHINE_LOG_KPI, abortController);
    return kpi;
}

export async function fetchManagementViewKpis(equipment: string): Promise<ManagementViewKpis> {
    const kpi = await getJson(`/api/v1/management-view-kpis`, { equipment }, EntityTypes.MANAGEMENT_VIEW_KPI);
    return kpi;
}


export async function fetchLiveGraphsData(): Promise<LiveGraphsResult> {
    const start = dateWithoutTime(new Date());
    start.setDate(start.getDate() - 7);
    const shiftTableRange = { start, end: new Date() };

    const result = await Promise.all([
        getJson(`/api/v1/equipments`),
        getJson(`/api/v1/graphs-data`, { windowType: 'shift', ...shiftTableRange }, EntityTypes.OEE),
        getJson(`/api/v1/graphs-data`, { windowType: 'hour', ...shiftTableRange }, EntityTypes.OEE),
        getJson(`/api/v1/graphs-data`, { windowType: 'live' }, EntityTypes.OEE),
    ]);
    return {
        equipments: result[0],
        shiftTable: result[1],
        hourTable: result[2],
        liveTable: result[3],
    };
}

interface LiveGraphsResult {
    equipments: Equipment[];
    shiftTable: OeeRow[];
    hourTable: OeeRow[];
    liveTable: OeeRow[];
}


export async function fetchOperators(): Promise<Operator[]> {
    const users = await getJson(`/api/v1/operators`);
    return users;
}

export async function putOperator(operator: Operator): Promise<void> {
    const response = await putJson(`/api/v1/operators/${operator._id}`, operator);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function fetchPartCountsAt(session: Session, times: Date[]): Promise<number[]> {
    const response = await postJson(
        '/api/v1/sessions/get-part-counts-at',
        { session, times, }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const partCounts = await response.json() as number[];
    return partCounts;
}


export async function putPrice(partName: string, productPrice: number): Promise<boolean> {
    const response = await putJson(`/api/v1/products/price`, { partName, productPrice });
    return response.ok && response.status === 200;
}


export async function fetchDiagnostics(): Promise<DiagnosticsData> {
    const diagnostics = await getJson(`/api/v1/diagnostics`, null, EntityTypes.DIAGNOSTICS);
    return diagnostics;
}

export async function postServerRestart(userMessage: string, diagnostics?: DiagnosticsData) {
    postJson(`/api/v1/diagnostics/restart`, {
        userMessage,
        diagnostics,
    });
}
