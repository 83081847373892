import { useEffect, useState } from 'react';
import ModalSubmitDialog from '../modalSubmitDialog';
import TriStateToggle from '../triStateToggle';
import { ImportIssuesResult, Measurement } from './types';


interface ImportSelectionDialogProps {
    isOpen: boolean;
    importIssues: ImportIssuesResult[];
    onCancel: () => void;
    onSubmit: (valuesToImport: Measurement[]) => void;
}

export default function ImportSelectionDialog({
    isOpen,
    importIssues,
    onCancel,
    onSubmit,
}: ImportSelectionDialogProps) {
    const [measurementSelections, setMeasurementSelections] = useState<boolean[]>([]);


    useEffect(() => {
        setMeasurementSelections(importIssues.map(it => it.isImportable));
    }, [importIssues]);

    function handleSelectChange(index: number, isSelected: boolean) {
        const newMeasurementSelections = [...measurementSelections];
        newMeasurementSelections[index] = isSelected;
        setMeasurementSelections(newMeasurementSelections);
    }

    function handleSubmit() {
        const selectedMeasurements = importIssues
            .filter((_, index) => measurementSelections[index])
            .map(it => ({ position: it.position, value: it.value }));
        onSubmit(selectedMeasurements);
    }


    if (!isOpen) return null;
    return (
        <ModalSubmitDialog isOpen={isOpen} onCancel={onCancel} onSubmit={handleSubmit}>
            <table className='position-import-table'>
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Value</th>
                        <th>Issues</th>
                        <th>Import</th>
                    </tr>
                </thead>
                <tbody>
                    {importIssues?.map((it, index) => (
                        <tr key={index}>
                            <td>{it.position}</td>
                            <td>{it.value}</td>
                            <td style={{ whiteSpace: 'pre-wrap' }}>{it.issues.join('\n')}</td>
                            <td>
                                <TriStateToggle
                                    selection={measurementSelections[index]}
                                    onSelectionChange={(isSelected) => handleSelectChange(index, isSelected)}
                                    disabled={!it.isImportable}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ModalSubmitDialog>
    )
}
