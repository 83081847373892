import classNames from 'classnames';
import { useState } from 'react';
import Input from '../../components/input';
import { normalizeFloat } from '../../utils/positionInputParse';
import FormPositionMenu, { FormPositionMenuState } from './formPositionMenu';
import { useProtocolForm } from './protocolFormContext';
import { useUpdateProtocol } from './useUpdateProtocol';
import { Position } from '../../types/sharedTypeImpl';


interface ProtocolFormPositionsProps {
    isReadOnly: boolean;
}

export default function ProtocolFormPositions({
    isReadOnly,
}: ProtocolFormPositionsProps) {
    const [positionMenu, setPositionMenu] = useState<FormPositionMenuState | null>(null);

    const {
        protocol,
        activePositionIdx,
        showAllPositions,
        setShowAllPositions,
    } = useProtocolForm();

    const {
        setActivePositionIdx,
        setPositionField,
        removePosition,
        reorderPositions,
    } = useUpdateProtocol();


    function handleUpdatePosition(index: number, field: string, value: string) {
        setPositionField(index, field as keyof Position, value);
    }

    function handleMenuOpen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        if (isReadOnly) return;
        console.log({ x: e.screenX, y: e.screenY });
        setPositionMenu({ activePositionIdx, screenCoords: { x: e.pageX, y: e.pageY } });
    }

    function handleSelectSetDescription(activePositionIdx: number) {
        const currentDescription = protocol.positions[activePositionIdx].onPdfDescription;
        const description = prompt('Enter a description', currentDescription);
        if (description != null) {
            handleUpdatePosition(activePositionIdx, 'onPdfDescription', description);
        }
    }

    function handleDeletePosition(activePositionIdx: number) {
        const positionNumber = protocol.positions[activePositionIdx].positionNumber;
        if (confirm(`Delete position ${positionNumber}?`)) {
            removePosition(activePositionIdx);
        }
    }


    return (
        <>
            <table className='measurement-form__positions'>
                <thead>
                    <tr>
                        <th style={{ width: '65px' }} />
                        <th>Nominal</th>
                        <th style={{ width: '70px' }} >Symbol</th>
                        <th>Upper tol.</th>
                        <th>Lower tol.</th>
                        <th style={{ width: '75px' }} >Use GT</th>
                        <th style={{ width: '80px' }} >Chamfer</th>
                        <th style={{ width: '80px' }} >Tool</th>
                        <th style={{ width: '40px' }} />
                    </tr>
                </thead>
                <tbody>
                    {protocol.positions?.map((it, index) =>
                        it.type === 'measurement'
                            ? <MeasurementPosition
                                key={it.key}
                                position={it}
                                isActive={index == activePositionIdx}
                                index={index}
                                isReadOnly={isReadOnly}
                                onSelect={() => setActivePositionIdx(index)}
                                onUpdatePositionValue={handleUpdatePosition}
                                onMenuOpenClick={handleMenuOpen}
                            />
                            : <VisualInspection
                                key={it.key}
                                position={it}
                                isActive={index == activePositionIdx}
                                index={index}
                                isReadOnly={isReadOnly}
                                onSelect={() => setActivePositionIdx(index)}
                                onUpdatePositionValue={handleUpdatePosition}
                                onMenuOpenClick={handleMenuOpen}
                            />
                    )}
                </tbody>
            </table>

            <div className='flex--horz' style={{ gap: '20px', justifyContent: 'left', marginTop: '8px' }}>
                {!isReadOnly &&
                    <button
                        style={{ display: 'block' }}
                        type='button'
                        onClick={reorderPositions} >
                        Reorder positions
                    </button>
                }
                <label className='with-checkbox'>
                    Show all positions
                    <input
                        type='checkbox'
                        checked={showAllPositions}
                        onChange={(e) => setShowAllPositions(e.target.checked)}
                    />
                </label>
            </div>

            {positionMenu && <FormPositionMenu
                activePositionIdx={positionMenu.activePositionIdx}
                screenCoords={positionMenu.screenCoords}
                onSelectSetDescription={handleSelectSetDescription}
                onSelectDelete={handleDeletePosition}
                onClose={() => setPositionMenu(null)}
            />}
        </>
    )
}


interface MeasurementPositionProps {
    position: Position,
    index: number,
    isActive: boolean,
    isReadOnly: boolean,
    onSelect: () => void,
    onUpdatePositionValue: (index: number, field: string, value: string | boolean) => void,
    onMenuOpenClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}
function MeasurementPosition({
    position,
    index,
    isActive,
    isReadOnly,
    onSelect,
    onUpdatePositionValue,
    onMenuOpenClick,
}: MeasurementPositionProps) {
    const isAuto = position.toolType === 'CMM' || position.toolType === 'VMM';
    const rowClass = classNames({
        'highlight--selected': isActive && !isAuto,
        'automatic': !isActive && isAuto,
        'automatic--selected': isActive && isAuto,
    });

    const reformatOnBlur = (e: React.FocusEvent<HTMLInputElement>, field: keyof Position) => {
        const value = e.target.value;
        const newValue = normalizeFloat(value);
        onUpdatePositionValue(index, field, newValue);
    }

    return (
        <tr
            className={rowClass}
            onClick={onSelect}
        >
            {/* {`page: ${position.page}, x: ${position.x.toFixed(0)}, y: ${position.y.toFixed(0)}`} <br /> */}
            <td>
                <Input
                    type='number'
                    value={position.positionNumber}
                    onChange={(e) => { onUpdatePositionValue(index, 'positionNumber', e.target.value) }}
                    onFocus={onSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <Input
                    type='number'
                    value={position.nominal ?? ''}
                    onChange={(e) => { onUpdatePositionValue(index, 'nominal', e.target.value) }}
                    onFocus={onSelect}
                    onBlur={(e) => reformatOnBlur(e, 'nominal')}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <input
                    value={position.symbol ?? ''}
                    onChange={(e) => {
                        onUpdatePositionValue(index, 'symbol', e.target.value)
                    }}
                    onFocus={onSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <Input
                    type='number'
                    value={position.upperTolerance ?? ''}
                    onChange={(e) => { onUpdatePositionValue(index, 'upperTolerance', e.target.value) }}
                    onFocus={onSelect}
                    onBlur={(e) => reformatOnBlur(e, 'upperTolerance')}
                    readOnly={isReadOnly || position.useGeneralTolerance}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <Input
                    type='number'
                    value={position.lowerTolerance ?? ''}
                    onChange={(e) => { onUpdatePositionValue(index, 'lowerTolerance', e.target.value) }}
                    onFocus={onSelect}
                    onBlur={(e) => reformatOnBlur(e, 'lowerTolerance')}
                    readOnly={isReadOnly || position.useGeneralTolerance}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <input
                    type='checkbox'
                    name='useGeneralTolerance'
                    checked={position.useGeneralTolerance ?? false}
                    onChange={(e) => { onUpdatePositionValue(index, 'useGeneralTolerance', e.target.checked) }}
                    onFocus={onSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <input
                    type='checkbox'
                    name='isChamfer'
                    checked={position.isChamfer ?? false}
                    onChange={(e) => { onUpdatePositionValue(index, 'isChamfer', e.target.checked) }}
                    onFocus={onSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <select
                    name='toolType'
                    value={position.toolType ?? ''}
                    onChange={(e) => { onUpdatePositionValue(index, 'toolType', e.target.value) }}
                    onFocus={onSelect}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                >
                    {position.toolType == null && <option value='' />}
                    <option value='CAL'>CAL</option>
                    <option value='TG'>TG</option>
                    <option value='PG'>PG</option>
                    <option value='MM'>MM</option>
                    <option value='CMM'>CMM</option>
                    <option value='VMM'>VMM</option>
                    <option value='Other'>Other</option>
                </select>
            </td>
            <td>
                <button
                    className='td__menu-button'
                    onClick={onMenuOpenClick}
                    onFocus={onSelect}>
                    &#8942;
                </button>
            </td>

            {/* {isActive && !isReadOnly && <button type='button' onClick={onSetMarkerClicked}>Set marker</button>} */}
        </tr>
    );
}

interface VisualInspectionProps {
    position: Position,
    index: number,
    isActive: boolean,
    isReadOnly: boolean,
    onSelect: () => void,
    onUpdatePositionValue: (index: number, field: string, value: string | boolean) => void,
    onMenuOpenClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}
function VisualInspection({
    position,
    index,
    isActive,
    isReadOnly,
    onSelect,
    onUpdatePositionValue,
    onMenuOpenClick,
}: VisualInspectionProps) {
    return (
        <tr
            className={`${isActive && 'highlight--selected'}`}
            onClick={onSelect}
        >
            <td>
                <Input
                    type='number'
                    value={position.positionNumber}
                    onChange={(e) => { onUpdatePositionValue(index, 'positionNumber', e.target.value) }}
                    onFocus={onSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td colSpan={7}>
                <input
                    type='text'
                    value={position.description ?? ''}
                    onChange={(e) => { onUpdatePositionValue(index, 'description', e.target.value) }}
                    onFocus={onSelect}
                    readOnly={isReadOnly}
                    style={{ pointerEvents: isReadOnly ? 'none' : 'auto' }} //Let clicks pass through when readonly
                />
            </td>
            <td>
                <button
                    className='td__menu-button'
                    onClick={onMenuOpenClick}
                    onFocus={onSelect}>
                    &#8942;
                </button>
            </td>
        </tr>
    )
}
