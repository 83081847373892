import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app';
import AppVersionCheck from './components/appVersionCheck';
import { initErrorReporting } from './utils/errorReporting';


initErrorReporting();
const queryClient = new QueryClient({});


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
            <AppVersionCheck />
        </QueryClientProvider>
    </React.StrictMode>
)
