import { Position, ProtocolFinal, RecordDraft } from '../../types/sharedTypeImpl';
import { ImportIssuesResult, Measurement } from './types';


export default function (
    protocol: ProtocolFinal,
    record: RecordDraft,
    importedValues: Measurement[]
): ImportIssuesResult[] {
    const importIssues = importedValues.map(importedValue => {
        const positionIdx = protocol.positions.findIndex(it => it.positionNumber === importedValue.position);
        const position = protocol.positions[positionIdx];
        const currentValue = record.positionValues[positionIdx]?.value;

        return getIssues(importedValue.position, importedValue.value, currentValue, position);
    });

    return importIssues;
}

function getIssues(
    positionNumber: string,
    importedValue: string,
    currentValue: string | boolean | undefined,
    position: Position | undefined,
): ImportIssuesResult {
    let isImportable = true;

    const issues = [];
    const parsedImportedValue = parseFloat(importedValue);
    if (position == undefined) {
        issues.push('Position not found');
        isImportable = false;
    } else if (position.type === 'visualInspection') {
        issues.push('Invalid position type');
        isImportable = false;
    } else if (Number.isNaN(parsedImportedValue) || importedValue.length === 0) {
        issues.push('Invalid value');
        isImportable = false;
    } else {
        const max = +position.nominal + +position.upperTolerance;
        const min = +position.nominal + +position.lowerTolerance;
        const isAboveMax = parsedImportedValue > max;
        const isBelowMin = parsedImportedValue < min;
        if (isAboveMax || isBelowMin) {
            issues.push(`Out of tolerance: ${min}...${max}`);
        }

        if (currentValue != undefined && currentValue !== '' && currentValue !== importedValue) {
            issues.push(`Value already exists: ${currentValue}`);
        }
    }

    return {
        position: positionNumber,
        value: importedValue,
        isImportable,
        isConflict: issues.length > 0,
        issues,
    };
}
