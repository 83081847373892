import { maxOf, minOf } from '../../utils/arrayUtils';
import { calcMean, calcStddev } from '../../utils/maths';
import { MIN_POINTS_FOR_SPC } from '../../pages/record-view/spcChecks';
import { DataPoint } from './transformDataPoints';
import { ProtocolFinal } from '../../types/sharedTypeImpl';


export interface MetaData {
    //from protocol
    nominal: number,
    lowerMeasureLimit: number,
    upperMeasureLimit: number,

    //from max and min values
    lowerGraphLimit: number,
    upperGraphLimit: number,
    lowerValueLimit: number,
    upperValueLimit: number,

    //from historical data
    mean: number,
    ucl?: number,
    lcl?: number,
    isLastValueFlagged?: boolean,
}

export default function getMetaData(data: DataPoint[], protocol: ProtocolFinal, selectedPositionIdx: number): MetaData {
    const minValue = minOf(data.filter(it => it.shouldDraw), it => it.value);
    const maxValue = maxOf(data.filter(it => it.shouldDraw), it => it.value);

    const position = selectedPositionIdx != null && protocol.positions[selectedPositionIdx];
    const nominal = +position.nominal;
    const lowerTolerance = +position.lowerTolerance;
    const upperTolerance = +position.upperTolerance;

    //Physical part measurement tolerance limits
    const lowerMeasureLimit = nominal + lowerTolerance;
    const upperMeasureLimit = nominal + upperTolerance;

    //Visible value limits, sets the graph scale
    //We sometimes use average tolerance, in case one of the tolerances is zero.
    const averageTolerance = (Math.abs(lowerTolerance) + upperTolerance) / 2;
    const graphLimitMargin = 0.1 * averageTolerance;
    const lowerGraphLimit = Math.max(
        nominal + 1.75 * Math.min(lowerTolerance, -averageTolerance),
        Math.min(lowerMeasureLimit, minValue) - graphLimitMargin);
    const upperGraphLimit = Math.min(
        nominal + 1.75 * Math.max(upperTolerance, averageTolerance),
        Math.max(upperMeasureLimit, maxValue) + graphLimitMargin);

    //Value limits for data points (helps avoid lines that look completely vertical)
    const lowerValueLimit = nominal + 3 * Math.min(lowerTolerance, -averageTolerance);
    const upperValueLimit = nominal + 3 * Math.max(upperTolerance, averageTolerance);

    //Mean and 3 sigma lines
    const values = data.map(it => it.value);
    const mean = calcMean(values);
    const stddev = calcStddev(values);
    const ucl = mean + 3 * stddev;
    const lcl = mean - 3 * stddev;
    const isLastValueFlagged = data?.at(-1)?.failedSpcCheck ?? false;

    const canDoSpc = values.length >= MIN_POINTS_FOR_SPC;
    const spcValues = canDoSpc ? { stddev, ucl, lcl, isLastValueFlagged } : null;


    return {
        nominal,
        lowerMeasureLimit,
        upperMeasureLimit,
        lowerGraphLimit,
        upperGraphLimit,
        lowerValueLimit,
        upperValueLimit,
        mean,
        ...spcValues,
    }
}