import { Measurement, ReadMeasurementsResult } from './types';


export default async function readZeissCmmFile(xlsxFile: File): Promise<ReadMeasurementsResult> {
    try {
        const xlsx = await import('node-xlsx');

        const arrayBuffer = await xlsxFile.arrayBuffer();
        const document = xlsx.parse(arrayBuffer, { raw: true });
        const worksheet = document[0].data;

        const validation = worksheet[11][0] === 'Characteristic' && worksheet[11][1] === 'Actual';
        if (!validation) {
            return { success: false, errorMessage: 'Unrecognized Zeiss format' };
        }

        const measurements: Measurement[] = [];
        worksheet.slice(12).forEach(row => {
            const position = row[0]?.match(/Pos (\d+(\.\d+)?)(_[XYZ])?/)?.[1];
            const valueAsFloat = Number.parseFloat(row[1]);
            if (!position || isNaN(valueAsFloat)) return;
            measurements.push({ position, value: valueAsFloat.toFixed(3) });
        });

        return {
            success: true,
            source: 'cmm',
            sourceSubType: 'zeiss',
            measurements,
        }
    } catch (error) {
        console.error('Error reading file:', error);
        return { success: false, errorMessage: 'Error reading file' };
    }
}
