import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { ProtocolImageEntry } from '../../types';
import { ProtocolDraft } from '../../types/sharedTypeImpl';


interface ProtocolFormContextType {
    protocol: ProtocolDraft | null;
    setProtocol: Dispatch<SetStateAction<ProtocolDraft | null>>;
    pdfData: Uint8Array | null;
    setPdfData: (pdfData: Uint8Array | null) => void;
    activePositionIdx: number | null;
    setActivePositionIdx: (activePositionIdx: number | null) => void;
    showAllPositions: boolean;
    setShowAllPositions: (showAllPositions: boolean) => void;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
    getPositionImages: () => ProtocolImageEntry[];
}

const ProtocolFormContext = createContext<ProtocolFormContextType | undefined>(undefined);


export function ProtocolFormProvider({ children }) {
    const [protocol, setProtocol] = useState<ProtocolDraft | null>(null);
    const [pdfData, setPdfData] = useState<Uint8Array | null>(null);
    const [activePositionIdx, setActivePositionIdx] = useState<number | null>(null);
    const [showAllPositions, setShowAllPositions] = useState(false);
    const [isDirty, setIsDirty] = useState(false);


    const getPositionImages = (): ProtocolImageEntry[] => {
        const images = [];
        for (let i = 0; i < protocol.positions.length; i++) {
            if (!protocol.positions[i].imageFile) continue;
            images.push({
                image: protocol.positions[i].imageFile!,
                positionIdx: i
            });
        }
        return images;
    }


    return (
        <ProtocolFormContext.Provider
            value={{
                protocol, setProtocol,
                pdfData, setPdfData,
                activePositionIdx, setActivePositionIdx,
                showAllPositions, setShowAllPositions,
                isDirty, setIsDirty,
                getPositionImages,
            }}
        >
            {children}
        </ProtocolFormContext.Provider>
    );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useProtocolForm(): ProtocolFormContextType {
    return useContext(ProtocolFormContext);
}
