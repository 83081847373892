import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { Point } from '../../types';


export interface CircleMarkerTriggers {
    updatePosition: () => void;
}

interface CircleMarkerProps {
    pdfPosition?: Point;
    pdfToScreenCoords: (pdfCoords: Point) => Point;
    description?: string;
}

const CircleMarker = forwardRef<CircleMarkerTriggers, CircleMarkerProps>(({
    pdfPosition,
    pdfToScreenCoords,
    description,
}, ref) => {
    const markerContainer = useRef<HTMLDivElement>(null);


    useImperativeHandle(ref, () => ({
        updatePosition,
    }));

    const updatePosition = useCallback(() => {
        if (pdfPosition?.x == null || pdfPosition?.y == null) return;
        const screenPositionFinal = pdfToScreenCoords({ x: pdfPosition.x, y: pdfPosition.y });
        markerContainer.current.style.left = `${screenPositionFinal.x}px`;
        markerContainer.current.style.top = `${screenPositionFinal.y}px`;
    }, [pdfPosition?.x, pdfPosition?.y, pdfToScreenCoords]);

    //Calls updatePosition when pdfPosition changes
    useEffect(() => {
        updatePosition();
    }, [updatePosition]);


    if (!pdfPosition) {
        return null;
    }

    return (
        <div
            ref={markerContainer}
            className='marker-container'
            style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
            }} >
            <div style={{
                width: '50px',
                height: '50px',
                border: '2px solid red',
                borderRadius: '50%',
                boxSizing: 'border-box',
                backgroundColor: 'transparent',
                pointerEvents: 'none',
            }} />

            {description &&
                <div style={{
                    width: 'max-content',
                    maxWidth: '400px',
                    position: 'absolute',
                    left: '125%',
                    top: '-50%',
                    transform: 'translateY(-50%)',
                    background: '#fff',
                    border: '2px solid #f00',
                    borderRadius: '5px',
                    padding: '8px'
                }}>
                    {description}
                </div>
            }
        </div>
    );
});
export default CircleMarker;
